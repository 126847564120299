import './PlanTime.scss'

export const PlanTime = ({ timeText }: { timeText: string }) => {
  return (
    <div className="PlanTime">
      <i className="bi bi-clock PlanTime__icon"></i>
      <h5 className="PlanTime__text">{timeText}</h5>
    </div>
  )
}
