import { useSelector } from 'react-redux'
import BottomDrawer from '../BottomDrawer/BottomDrawer'
import Modal from '../Modal/Modal'

export const ModalOrDrawer = ({
  setIsOpen,
  content,
  height,
}: {
  setIsOpen: (isOpen: boolean) => void
  content: any
  height: 'auto' | 'fill'
}) => {
  const isMobile = useSelector((state: any) => {
    return state.isMobile
  })

  if (isMobile) {
    return (
      <BottomDrawer className={height === 'auto' ? 'AutoHeight' : 'FillHeight'} onHide={() => setIsOpen(false)}>
        {content}
      </BottomDrawer>
    )
  } else {
    return <Modal onClose={() => setIsOpen(false)}>{content}</Modal>
  }
}
