import './ChooseImage.scss'
import { ConfirmationButtons } from '../../ConfirmationButtons/ConfirmationButtons'
import { useQuery } from '@apollo/client'
import { planImagesFind } from '../../../graphql/queries/plan-images-find'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import { PlanEditView } from '../../../types/PlanEditView.enum'

const ChooseImage = ({
  setView,
  planImageUrl,
  setPlanImageUrl,
  setCustomImage,
}: {
  setView: (view: PlanEditView) => void
  planImageUrl: string
  setPlanImageUrl: (url: string) => void
  setCustomImage: (image: string | null) => void
}) => {
  const isMobile = useSelector((state: any) => {
    return state.isMobile
  })

  // TODO: add loading state
  const { loading, data } = useQuery(planImagesFind)
  const imageUrls = data?.plans?.findPlanIconImageUrls || []

  const [picPreviewUrl, setPicPreviewUrl] = useState(planImageUrl)
  const [uploadedImage, setUploadedImage] = useState<string | null>(null)
  const [didError, setDidError] = useState<boolean>(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const onCancelClick = () => {
    setView(PlanEditView.planEdit)
  }
  const onContinueClick = () => {
    setView(PlanEditView.planEdit)

    if (uploadedImage) {
      // for custom images, we need to send a separate request to save the image
      setCustomImage(uploadedImage || null)
    }
    setPlanImageUrl(picPreviewUrl)
  }

  const onImageUploadChange = (e: any) => {
    if (e.target.files[0]) {
      if (e.target.files[0].size > 20000000) {
        e.target.value = null
        setDidError(true)
      } else {
        setDidError(false)
        setPicPreviewUrl(URL.createObjectURL(e.target.files[0]))
        setUploadedImage(e.target.files[0])
      }
    }
  }

  const choosePieImage = (e: any) => {
    setDidError(false)
    setPicPreviewUrl(e.target.src)
    setUploadedImage(null)
  }

  const column1Fragment = (
    <div className="ChooseImage__column-1 Flex Flex--col Flex--align-center">
      <button className="Button-secondary Button-medium Mb-2" onClick={() => fileInputRef.current?.click()}>
        <i className="bi bi-image Mr-1" />
        Use a Photo
      </button>
      <p className={didError ? 'Caption Pink-text ' : 'Caption'}>Image cannot exceed 20MB</p>
      {/* Hidden File Input, triggered via "Use a Photo" button */}
      <div style={{ height: '0px', overflow: 'hidden' }} className="Mt-12">
        <input type="file" accept="image/*" onChange={onImageUploadChange} ref={fileInputRef} />
      </div>
      <h5>Or select an image</h5>
      <div className="ChooseImage__images-grid Mt-6">
        {loading && (
          <>
            <Skeleton className="ChooseImage__images-grid__loading-skeleton" />
            <Skeleton className="ChooseImage__images-grid__loading-skeleton" />
            <Skeleton className="ChooseImage__images-grid__loading-skeleton" />
            <Skeleton className="ChooseImage__images-grid__loading-skeleton" />
            <Skeleton className="ChooseImage__images-grid__loading-skeleton" />
            <Skeleton className="ChooseImage__images-grid__loading-skeleton" />
          </>
        )}
        {imageUrls.map((imageUrl: string) => (
          <div key={imageUrl}>
            <div
              className={`${
                picPreviewUrl === imageUrl
                  ? 'ChooseImage__images-grid__image ChooseImage__images-grid__image--selected'
                  : 'ChooseImage__images-grid__image'
              }`}
            >
              {picPreviewUrl === imageUrl && <div className="ChooseImage__images-grid__image__icon bi bi-check" />}
              <img src={imageUrl} onClick={choosePieImage} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  const column2Fragment = (
    <div className="ChooseImage__sticky-image-preview">
      <div className="ChooseImage__column-2 Flex Flex--col Flex--align-center G-2">
        {<img className="ChooseImage__preview-image" src={picPreviewUrl} />}
        <p className="Caption">Preview</p>
      </div>
    </div>
  )

  return (
    <>
      <div className="ChooseImage Flex Flex--col Flex--align-center">
        {!isMobile && (
          <div className="Flex G-9">
            {column1Fragment}
            {column2Fragment}
          </div>
        )}
        {isMobile && (
          <>
            {column2Fragment}
            {column1Fragment}
          </>
        )}
      </div>
      <div className="ChooseImage__sticky-buttons">
        <ConfirmationButtons saveButtonText="Done" onCancelClick={onCancelClick} onContinueClick={onContinueClick} />
      </div>
    </>
  )
}

export { ChooseImage }
