import './ChannelInviteFriendsButton.scss'
import { useSelector } from 'react-redux'
import { Plan } from '../../../__generated__/graphql'
import { useState } from 'react'
import { User } from 'stream-chat'
import { createInviteFriendsClickedEvent } from '../ChannelInviteFriendsCallout/ChannelInviteFriendsCallout'
import { ModalOrDrawer } from '../../ModalOrDrawer/ModalOrDrawer'

export function ChannelInviteFriendsButton({
  plan,
  dynamicLinkUrl,
}: {
  plan: Plan
  dynamicLinkUrl: string
}): JSX.Element | null {
  const user = useSelector((state: any) => state.user as User)
  const [inviteFriendsButtonText, setInviteFriendsButtonText] = useState('Invite Friends')
  const [planAtCapacityModalIsOpen, setPlanAtCapacityModalIsOpen] = useState(false)

  const copyLink = async () => {
    setInviteFriendsButtonText('Link copied!')
    navigator.clipboard.writeText(dynamicLinkUrl)
    setTimeout(() => {
      setInviteFriendsButtonText('Invite Friends')
    }, 3000)
  }

  const openMobileShareSheet = async () => {
    if (plan.capacity?.atCapacity) {
      setPlanAtCapacityModalIsOpen(true)
    } else {
      try {
        navigator.share({
          title: plan.title,
          url: dynamicLinkUrl,
        })
      } catch (err) {
        // fail-safe if native share sheet can't be opened
        copyLink()
      }
      createInviteFriendsClickedEvent(plan, user?.id)
    }
  }

  const planAtCapaciryModalContent = (
    <div className="Flex Flex--col Flex--align-center G-3">
      <h2 className="Text-centered">Increase Plan Capacity to Invite more people</h2>
      <p className="Text-centered">
        This plan has reached capacity. To invite more people increase capacity in Plan Settings.
      </p>
      <button className="Button-primary Button-large Fill-width" onClick={() => setPlanAtCapacityModalIsOpen(false)}>
        Got it
      </button>
    </div>
  )

  return (
    <div className="ChannelInviteFriendsButton">
      {planAtCapacityModalIsOpen && (
        <ModalOrDrawer setIsOpen={setPlanAtCapacityModalIsOpen} height="auto" content={planAtCapaciryModalContent} />
      )}
      <button
        className="ChannelInviteFriendsButton__button Flex Flex--align-center Button-primary Button-large"
        onClick={openMobileShareSheet}
      >
        <i className="bi bi-send ChannelInviteFriendsButton__button__icon"></i>
        <p>{inviteFriendsButtonText}</p>
      </button>
    </div>
  )
}
