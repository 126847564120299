import axios from 'axios'
import { store } from '../store/store'

export const useUploadUserPicture = ({
  userPic,
  authToken,
  setIsLoading,
  setError,
}: {
  userPic: string
  authToken: string
  setIsLoading: (value: React.SetStateAction<boolean>) => void
  setError: (value: React.SetStateAction<any>) => void
}) => {
  if (!userPic) {
    return
  }
  setIsLoading(true)
  const formData = new FormData()
  formData.append('file', userPic)
  axios
    .post(`${process.env.REACT_APP_API_HOST}/users/profile-picture/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: authToken ? `Bearer ${authToken}` : '',
      },
    })
    .then((res) => {
      store.dispatch({ type: 'user/upsert', user: res.data })
    })
    .catch((err) => {
      console.warn('Could not upload picture', err)
      setError(err)
    })
    .finally(async () => {
      setIsLoading(false)
    })
}
