import { Channel, StreamChat } from 'stream-chat'
import 'stream-chat-react/dist/css/v2/index.css'
import { Plan, User } from '../../__generated__/graphql'
import { useSelector } from 'react-redux'
import Chat from '../Chat/Chat'
import { streamApiKey } from '../../constants/constants'
import { useGetStreamChannel } from '../../hooks/useGetStreamChannel'
import './ChannelChat.scss'

const ChannelChat = ({
  plan,
  isChatOpen,
  handleChatBackButton,
}: {
  plan: Plan
  isChatOpen: boolean
  handleChatBackButton: () => void
}): JSX.Element | null => {
  const user = useSelector((state: any) => state.user as User)
  const streamChatClient: StreamChat = StreamChat.getInstance(`${streamApiKey}`)
  const streamChannel: Channel = useGetStreamChannel(streamChatClient, plan, user?.id)
  const isMobile = useSelector((state: any) => {
    return state.isMobile
  })

  if (streamChatClient && isChatOpen && user?.id) {
    if (isMobile) {
      return (
        <div className="ChannelChat--mobile">
          <Chat
            client={streamChatClient}
            streamChannel={streamChannel}
            plan={plan}
            handleChatBackButton={handleChatBackButton}
          />
        </div>
      )
    } else {
      return (
        <div className="ChannelChat--desktop Card">
          <div className="ChannelChat--desktop__chat-container">
            <Chat
              client={streamChatClient}
              streamChannel={streamChannel}
              plan={plan}
              handleChatBackButton={handleChatBackButton}
            />
          </div>
        </div>
      )
    }
  }
  return null
}

export { ChannelChat }
