import { store } from '../../store/store'
import { useEffect } from 'react'
import { getMessaging, getToken, Messaging } from '@firebase/messaging'
import { FirebaseErrorCodes } from '../../types/firebase-error-codes.enum'
import { useMutation } from '@apollo/client'
import { DeviceTokensMutations } from '../../__generated__/graphql'
import { deviceTokensUpsert } from '../../graphql/mutations/device-tokens-upsert'
import { useSelector } from 'react-redux'
import { didEnableWebPushNotifications } from '../../constants/constants'

export function InitializeWebPushNotifications(): null {
  const authToken = useSelector((state: any) => state.authToken as string)
  const [upsertDeviceTokenMutation] = useMutation<{ deviceTokens: DeviceTokensMutations }>(deviceTokensUpsert)
  useEffect(() => {
    if (didEnableWebPushNotifications && authToken) {
      const initFirebase = async () => {
        const messaging: Messaging = getMessaging()
        const deviceToken = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        }).catch((err) => {
          if (err.code !== FirebaseErrorCodes.webPushDenied) console.error('Error getting web push token', err)
        })
        store.dispatch({ type: 'deviceToken/setToken', deviceToken: deviceToken || null })
        if (deviceToken) {
          await upsertDeviceTokenMutation({
            variables: { token: deviceToken },
          })
        }
      }
      initFirebase().catch((err) => {
        console.error('Error InitializeWebPushNotifications', err)
      })
    }
  }, [authToken])
  return null
}
