import { gql } from '@apollo/client'

export const plansCreate = gql`
  mutation plansCreate(
    $title: String!
    $timezone: String!
    $imageUrl: String
    $isVisibilityOpen: Boolean
    $guestCanInvite: Boolean
    $isSnowball: Boolean
    $enableRequestToJoin: Boolean
    $maxCapacity: Int
    $description: String
    $startTimeOnly: DateTime
    $startDateOnly: DateTime
    $startTimeText: String
    $endTimeText: String
    $dates: [PlanDateInput!]
    $links: [PlanLinkInput!]
    $places: [PlanPlaceInput!]
  ) {
    plans {
      create(
        input: {
          title: $title
          timezone: $timezone
          imageUrl: $imageUrl
          isVisibilityOpen: $isVisibilityOpen
          guestCanInvite: $guestCanInvite
          isSnowball: $isSnowball
          enableRequestToJoin: $enableRequestToJoin
          maxCapacity: $maxCapacity
          description: $description
          startTimeOnly: $startTimeOnly
          startDateOnly: $startDateOnly
          startTimeText: $startTimeText
          endTimeText: $endTimeText
          dates: $dates
          links: $links
          places: $places
        }
      ) {
        id
        title
        imageUrl
      }
    }
  }
`
