import { type CalendarVariantValue, CALENDAR_VARIANT } from './constants'
import GoogleLight from '../../assets/svg/GoogleLight.svg'
import Apple from '../../assets/svg/Apple.svg'
import Outlook from '../../assets/svg/Outlook.svg'
import { CalendarEvent, google, ics, outlook } from 'calendar-link'
import { useGenerateShareLinks } from '../../hooks/useGenerateShareLinks'
import { Plan } from '../../__generated__/graphql'

export interface CalendarLink {
  href: string
  alt: string
  logo: string
}

export function getCalendarLinkProps(type: CalendarVariantValue, event: CalendarEvent): CalendarLink {
  switch (type) {
    case CALENDAR_VARIANT.Google:
      return {
        href: google(event),
        logo: GoogleLight,
        alt: 'Google Logo',
      }
    case CALENDAR_VARIANT.Apple:
      return {
        href: ics(event),
        logo: Apple,
        alt: 'Apple Logo',
      }
    case CALENDAR_VARIANT.Outlook:
      return {
        href: outlook(event),
        logo: Outlook,
        alt: 'Outlook Logo',
      }
    default:
      return {
        href: '',
        logo: '',
        alt: '',
      }
  }
}

export function createCalendarEvent(plan: Plan): CalendarEvent {
  const { data } = useGenerateShareLinks(plan.id)
  return {
    title: plan.title,
    start: buildCalendarDateString(plan) || new Date().toISOString(),
    duration: plan.startTimeText ? ([3, 'hour'] as [number, 'hour']) : undefined,
    allDay: !plan.startTimeText,
    description: data?.planUsers.generateShareLinks.dynamicLinkUrl || '',
  }
}

export function buildCalendarDateString(plan: Plan): string | null {
  if (plan.dates && plan.dates.length === 1 && plan.dates[0].text) {
    const calendarDate = plan.dates[0].text
    if (plan.startTimeText) {
      const time = plan.startTimeText.split(',')[1] || ''
      return `${calendarDate} • ${time}`.trim()
    }
    return calendarDate
  }
  return null
}
