import { dayjs } from './dayjs-util'

// expected inputs
// - 14:00
// - 01:35
// expected output
// - 1/31/2024, 02:00 PM
// - 1/31/2024, 01:35 AM
export const timeToStartTimeText = (inputDateString: string) => {
  return dayjs()
    .hour(parseInt(inputDateString.split(':')[0]))
    .minute(parseInt(inputDateString.split(':')[1]))
    .second(0)
    .millisecond(0)
    .format('M/D/YYYY, hh:mm A')
}
