import { gql } from '@apollo/client'

// Used for rsvping no
export const planUsersDecline = gql`
  mutation planUsersDecline($planId: String!) {
    planUsers {
      decline(input: { planId: $planId })
    }
  }
`
