import { useSelector } from 'react-redux'
import Header from '../Header/Header'
import { PlanEdit } from '../PlanEdit/PlanEdit'
import { User } from '../../__generated__/graphql'
import { SignUpOrLogInPage } from '../SignUpOrLogInPage/SignUpOrLogInPage'
import { useLocation } from 'react-router-dom'

export const PlanCreatePage = () => {
  const location = useLocation()

  const isMobile = useSelector((state: any) => {
    return state.isMobile
  })

  const user = useSelector((state: any) => state.user as User)
  const userLoaded = useSelector((state: any) => state.userLoaded as boolean)

  if (!userLoaded) {
    return null
  }

  if (!user?.id || !user.phoneNumber || !user.fullName || !user.imageUrl) {
    return <SignUpOrLogInPage />
  }

  return (
    <>
      {!isMobile && <Header />}
      <PlanEdit plan={location.state?.plan} />
    </>
  )
}
