import { UsersMutations } from '../../__generated__/graphql'
import './TermsUpdate.scss'
import InfoIconInCircle from '../../assets/svg/InfoIconInCircle.svg'
import ChevronDown from '../../assets/svg/ChevronDown.svg'
import { useMutation } from '@apollo/client'
import { usersUpdateMe } from '../../graphql/mutations/users-update-me'
import { store } from '../../store/store'

export const TermsUpdate = () => {
  const [updateMeMutation] = useMutation<{ users: UsersMutations }>(usersUpdateMe, {
    variables: { tosAgreedVersion: 1 },
  })

  const handleAgreedWithTerms = async () => {
    const response = await updateMeMutation()
    if (response && response.data?.users.updateMe) {
      store.dispatch({ type: 'user/upsert', user: response.data?.users.updateMe })
    }
  }

  return (
    <div className="Terms-update">
      <div className="Terms-update-wrapper">
        <img src={InfoIconInCircle} />
        <h1 className="Terms-update-header">
          We’ve updated our <br />
          Terms of Service
        </h1>
        <p className="Terms-update-text"> You must agree to Pie’s updated Terms of Service to continue using Pie.</p>
        <a className="Terms-update-link" href="https://www.getpie.app/terms-of-service" target="_blank">
          View Terms of Service <img className="Terms-update-link-icon" src={ChevronDown} />
        </a>
        <button className="Terms-update-button-agree" onClick={handleAgreedWithTerms}>
          Agree to terms
        </button>
      </div>
    </div>
  )
}
