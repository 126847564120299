import { User } from '../../__generated__/graphql'
import './PlanAttribution.scss'

export const PlanAttribution = ({ friends, wasSeen }: { friends: User[]; wasSeen: boolean }) => {
  if (!friends || (!friends.length && wasSeen)) return null

  return (
    <div className="PlanAttribution Mb-2">
      <div className="PlanAttribution__container Header-5">
        {friends.length !== 0 && (
          <>
            <img className="PlanAttribution__container__image" src={friends[0].imageUrl || ''} />
            <p className="Secondary-text">{friends[0].firstName} joined</p>
          </>
        )}
      </div>
      {!wasSeen && (
        <div className="Subtitle PlanAttribution__new-label">
          <i className="bi bi-stars"></i> NEW
        </div>
      )}
    </div>
  )
}
