import { AnyAction, configureStore } from '@reduxjs/toolkit'
import mixpanel from 'mixpanel-browser'

export enum BottomSheet {
  getApp,
}

// TODO: add type for user
const initialState = {
  bottomSheet: null,
  userLoaded: false, // may not set user, but finished the operation
  authTokenLoaded: false, // may not set token, but finished the operation
  streamChatInitialized: false,
  user: {},
  authToken: null,
  deviceToken: null,
  chatToken: null,
  windowWidth: window.innerWidth,
}

function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case '@@INIT':
      return state
    case 'user/setLoaded':
      return {
        ...state,
        userLoaded: true,
      }
    case 'user/upsert':
      mixpanel.identify(action.user.id)
      return {
        ...state,
        user: state.user ? { ...state.user, ...action.user } : { ...action.user },
        userLoaded: true,
      }
    case 'auth/setToken':
      return {
        ...state,
        authToken: action.authToken,
        authTokenLoaded: true,
      }
    case 'deviceToken/setToken':
      return {
        ...state,
        deviceToken: action.deviceToken,
      }
    case 'chat/setToken':
      return {
        ...state,
        chatToken: action.chatToken,
      }
    case 'streamChat/setInitialized':
      return {
        ...state,
        streamChatInitialized: action.initialized,
      }
    case 'user/logout':
      mixpanel.reset()
      return {
        ...state,
        userLoaded: false,
        user: null,
        authTokenLoaded: false,
        authToken: null,
        deviceToken: null,
      }
    case 'window/setWidth':
      return {
        ...state,
        windowWidth: action.width,
        isMobile: action.width <= 800 ? true : false,
        isMini: action.width <= 360 ? true : false,
      }
    default:
      if (!action.type.startsWith('@@redux/')) {
        console.error('Unknown action type dispatched', action)
      }
  }
  return state
}

export const store = configureStore({ reducer })
