import { useMutation } from '@apollo/client'
import { usersConnectionsAddFriend } from '../../../graphql/mutations/user-connections-add-friend'
import './AddPieFriend.scss'
import { usersConnectionsRemoveFriend } from '../../../graphql/mutations/user-connections-remove-friend'
import { User, UserConnectionStatus } from '../../../__generated__/graphql'
import { user } from '../../../graphql/queries/users-user'

export const AddPieFriend = ({ userProfile }: { userProfile: User }) => {
  const [addFriendMutation] = useMutation(usersConnectionsAddFriend, {
    variables: { userId: userProfile.id },
    optimisticResponse: {
      userConnections: {
        addFriend: true,
      },
    },
    refetchQueries: [user],
  })
  const [removeFriendMutation] = useMutation(usersConnectionsRemoveFriend, {
    variables: { userId: userProfile.id },
    optimisticResponse: {
      userConnections: {
        removeFriend: true,
      },
    },
    refetchQueries: [user],
  })

  const userConnectionStatus = userProfile.userConnectionStatus

  const handleAddPieFriend = () => {
    addFriendMutation()
  }

  const handleRemovePieFriend = () => {
    removeFriendMutation()
  }

  return (
    <>
      <hr />
      <div className="AddPieFriend Flex Flex--col Flex--align-center">
        {(userConnectionStatus === UserConnectionStatus.AddedMe || userConnectionStatus === null) && (
          <>
            <button className="AddPieFriend__button Button-primary Button-large" onClick={handleAddPieFriend}>
              Add as Pie Friend
            </button>
            <p className="Caption Mt-2">Add to share plans with them.</p>
          </>
        )}
        {(userConnectionStatus === UserConnectionStatus.AddedThem ||
          userConnectionStatus === UserConnectionStatus.BothAdded) && (
          <button className="AddPieFriend__button Button-secondary Button-large" onClick={handleRemovePieFriend}>
            Added as Pie Friend
          </button>
        )}
      </div>
    </>
  )
}
