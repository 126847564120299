import type { JSX } from 'react'
import PieSlice from '../../../assets/svg/PieSlice.svg'

export function WhatIsPie(): JSX.Element {
  return (
    <div className="EnhancedAuth__additional_info">
      <div className="EnhancedAuth__additional_info_header">
        <img src={PieSlice} alt="image of a slice of pie" />
        <b>what is pie?</b>
      </div>
      <p>
        pie’s here to end social isolation! <br />
        join to find unique plans near you. privacy is key – we only text about plans you rsvp to.
      </p>
    </div>
  )
}
