import { gql } from '@apollo/client'

export const userInterestsQuery = gql`
  query user($userId: String!) {
    users {
      user(id: $userId) {
        userInterests {
          interest {
            id
            name
          }
        }
      }
    }
  }
`
