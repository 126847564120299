import type { MouseEvent, MouseEventHandler, PropsWithChildren } from 'react'

import { useRef } from 'react'
import './Modal.scss'
import { useDisablePageScroll } from '../../hooks/useDisablePageScroll'

export interface ModalProps {
  onClose: MouseEventHandler<HTMLDivElement>
  enhanced?: boolean
  hideCloseButton?: boolean
}

const VARIANT = {
  normal: 'normal',
  enhanced: 'enhanced',
}

const Modal = ({ onClose, enhanced = false, children }: PropsWithChildren<ModalProps>) => {
  const modalRef = useRef<HTMLDivElement>(null)
  const refStyles = useRef({
    [VARIANT.normal]: {
      root: 'Modal',
      dialog: 'Modal-dialog',
      content: 'Modal-content',
      body: 'Modal-body',
    },
    [VARIANT.enhanced]: {
      root: 'EnhancedModal',
      dialog: 'EnhancedModal-dialog',
      content: 'EnhancedModal-content',
      body: 'EnhancedModal-body',
    },
  })

  useDisablePageScroll()

  function handleOverlayClick(event: MouseEvent<HTMLDivElement>) {
    if (event.target === modalRef.current) {
      onClose(event)
    }
  }

  const styles = refStyles.current[enhanced ? VARIANT.enhanced : VARIANT.normal]

  return (
    <div className={styles.root} role="dialog" ref={modalRef} onClick={handleOverlayClick}>
      <div className={styles.dialog} role="document">
        <div className={`${styles.content} Overflow-scroll`}>
          <div className={styles.body}>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Modal
