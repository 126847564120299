import type { JSX } from 'react'
import { FirebaseErrorCodes } from '../../../../types/firebase-error-codes.enum'

export function renderError(errorCode: string): JSX.Element | null {
  if (!errorCode) return null

  let errorMessage
  switch (errorCode) {
    case FirebaseErrorCodes.invalidPhoneNumber:
      errorMessage = 'Please enter a valid phone number.'
      break
    default:
      errorMessage = `Error: Unable to send SMS. Error code: ${errorCode}`
  }

  return <p className="EnhancedAuth__form__error">{errorMessage}</p>
}
