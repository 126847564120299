import './ChannelRecentMessages.scss'

import type { JSX } from 'react'

import { Message } from '../../../__generated__/graphql'

export interface ChannelRecentMessagesProps {
  messages: Message[]
  handleOpenChat: () => void
}

export function ChannelRecentMessages({ messages, handleOpenChat }: ChannelRecentMessagesProps): JSX.Element {
  const hasMessages = messages.length > 0
  return (
    <div className="ChannelRecentMessages Mt-12">
      <div className="Flex Flex--space-between">
        <h2 className="Mb-3">Messages</h2>
        <button className="Button-secondary Button-small" onClick={handleOpenChat}>
          See All
        </button>
      </div>
      {!hasMessages ? (
        <p className="Secondary-text">No messages yet</p>
      ) : (
        messages.map((message: Message) => (
          <div key={message.text} className="Flex Flex--align-end ChannelRecentMessages__message G-2 Mt-3">
            <img
              className="ChannelRecentMessages__message__image Circular-image"
              src={message.user.imageUrl ? message.user.imageUrl : undefined}
              alt={message.user.name || 'Profile Picture'}
            />
            <div className="ChannelRecentMessages__message__text">{message.text}</div>
          </div>
        ))
      )}
      <div className="Flex Flex--end Mt-3">
        <button className="Button-secondary Button-small" onClick={handleOpenChat}>
          <i className="bi bi-chat Mr-1" />
          {hasMessages ? 'Chat' : 'Start Chatting'}
        </button>
      </div>
    </div>
  )
}
