export const appStoreUrl = 'https://apps.apple.com/us/app/pie-make-plans-like-magic/id1509667820'
// WARN: Turning on web push notifications will break SMS integrations.
//  We need to think through who receives iOS push, web push, and SMS push.
export const didEnableWebPushNotifications = process.env.REACT_APP_DID_ENABLE_WEB_PUSH_NOTIFICATIONS === 'true' || false
export const showTerms = true
export const streamApiKey = process.env.REACT_APP_STREAM_API_KEY

export const morePlansForYouSource = 'morePlansForYou'

export const enablePlanCreation = process.env.REACT_APP_ENABLE_CREATING_PLANS === 'true' || false

export const enableEnhancedOnboarding = process.env.REACT_APP_ENABLE_ENHANCED_ONBOARDING === 'true' || false

export const screenShotUrl = process.env.REACT_APP_SCREENSHOT_URL ?? ''

export const apiV1Url = process.env.REACT_APP_API_V1_URL ?? ''

export const defaultImageUrl = `${process.env.REACT_APP_IMAGE_CDN_HOST}/plan-assets/icons-v4/02.png?width=400&optimize=low`

export const timezones = [
  { identifier: 'America/New_York', label: 'New York', shorthand: 'GMT-5' },
  { identifier: 'America/Chicago', label: 'Chicago', shorthand: 'GMT-6' },
  { identifier: 'America/Denver', label: 'Denver', shorthand: 'GMT-7' },
  { identifier: 'America/Phoenix', label: 'Phoenix', shorthand: 'GMT-7' },
  { identifier: 'America/Los_Angeles', label: 'Los Angeles', shorthand: 'GMT-8' },
  { identifier: 'America/Anchorage', label: 'Anchorage', shorthand: 'GMT-9' },
  { identifier: 'Pacific/Honolulu', label: 'Honolulu', shorthand: 'GMT-10' },
  { identifier: 'America/Vancouver', label: 'Vancouver', shorthand: 'GMT-8' },
  { identifier: 'America/Tijuana', label: 'Tijuana', shorthand: 'GMT-8' },
  { identifier: 'America/Mazatlan', label: 'Mazatlan', shorthand: 'GMT-7' },
  { identifier: 'America/Hermosillo', label: 'Hermosillo', shorthand: 'GMT-7' },
  { identifier: 'America/Mexico_City', label: 'Mexico City', shorthand: 'GMT-6' },
  { identifier: 'America/Winnipeg', label: 'Winnipeg', shorthand: 'GMT-6' },
  { identifier: 'America/Costa_Rica', label: 'Costa Rica', shorthand: 'GMT-6' },
  { identifier: 'America/Toronto', label: 'Toronto', shorthand: 'GMT-5' },
  { identifier: 'America/Jamaica', label: 'Hermosillo', shorthand: 'GMT-7' },
  { identifier: 'America/Puerto_Rico', label: 'Puerto Rico', shorthand: 'GMT-4' },
  { identifier: 'America/Sao_Paulo', label: 'Sao Paulo', shorthand: 'GMT-3' },
  { identifier: 'Europe/London', label: 'London', shorthand: 'GMT' },
  { identifier: 'Europe/Paris', label: 'Paris', shorthand: 'GMT+1' },
  { identifier: 'Europe/Berlin', label: 'Berlin', shorthand: 'GMT+1' },
  { identifier: 'Europe/Amsterdam', label: 'Amsterdam', shorthand: 'GMT+1' },
  { identifier: 'Asia/Jerusalem', label: 'Jerusalem', shorthand: 'GMT+2' },
  { identifier: 'Europe/Athens', label: 'Athens', shorthand: 'GMT+2' },
  { identifier: 'Africa/Johannesburg', label: 'Johannesburg', shorthand: 'GMT+2' },
  { identifier: 'Europe/Moscow', label: 'Moscow', shorthand: 'GMT+3' },
  { identifier: 'Africa/Kampala', label: 'Kampala', shorthand: 'GMT+3' },
  { identifier: 'Asia/Riyadh', label: 'Riyadh', shorthand: 'GMT+3' },
  { identifier: 'Asia/Dubai', label: 'Dubai', shorthand: 'GMT+4' },
  { identifier: 'Asia/Baku', label: 'Baku', shorthand: 'GMT+4' },
  { identifier: 'Asia/Karachi', label: 'Karachi', shorthand: 'GMT+5' },
  { identifier: 'Asia/Dhaka', label: 'Dhaka', shorthand: 'GMT+6' },
  { identifier: 'Asia/Bangkok', label: 'Bangkok', shorthand: 'GMT+7' },
  { identifier: 'Asia/Hong_Kong', label: 'Hong Kong', shorthand: 'GMT+8' },
  { identifier: 'Asia/Shanghai', label: 'Shanghai', shorthand: 'GMT+8' },
  { identifier: 'Australia/Perth', label: 'Perth', shorthand: 'GMT+8' },
  { identifier: 'Asia/Tokyo', label: 'Tokyo', shorthand: 'GMT+9' },
  { identifier: 'Asia/Seoul', label: 'Seoul', shorthand: 'GMT+9' },
  { identifier: 'Australia/Darwin', label: 'Darwin', shorthand: 'GMT+9:30' },
  { identifier: 'Australia/Adelaide', label: 'Adelaide', shorthand: 'GMT+10:30' },
  { identifier: 'Australia/Sydney', label: 'Sydney', shorthand: 'GMT+10' },
  { identifier: 'Pacific/Auckland', label: 'Auckland', shorthand: 'GMT+12' },
]
