import './ChannelInviteFriendsCallout.scss'
import { useSelector } from 'react-redux'
import { Plan, RsvpType, User } from '../../../__generated__/graphql'
import { useState } from 'react'
import { createMixpanelEvent } from '../../../util/create-mixpanel-event'
import { MixpanelEvent } from '../../../types/mixpanel-event.enum'
import { LoaderOnly } from '../../Loader/LoaderOnly'
import { useGenerateShareLinks } from '../../../hooks/useGenerateShareLinks'
import PartyPopper from '../../../assets/svg/PartyPopper.svg'

export function createInviteFriendsClickedEvent(plan: Plan, userId: string | undefined): void {
  createMixpanelEvent(MixpanelEvent.inviteFriendsClicked, {
    isPlanCreator: plan.ownerUserId === userId,
    planId: plan.id,
    planTitle: plan.title,
  })
}

export function ChannelInviteFriendsCallout({
  plan,
  setDynamicLinkUrl,
}: {
  plan: Plan
  setDynamicLinkUrl: (url: string) => void
}): JSX.Element | null {
  const user = useSelector((state: any) => state.user as User)
  const isMobile = useSelector((state: any) => {
    return state.isMobile
  })

  const [desktopCopyLinkButtonText, setDesktopCopyLinkButtonText] = useState('Copy link')
  const [mobileInviteFriendsButtonText, setMobileCopyLinkButtonText] = useState('Invite Friends')

  const { loading, data } = useGenerateShareLinks(plan.id)

  const dynamicLinkUrl = data?.planUsers.generateShareLinks.dynamicLinkUrl
  if (!dynamicLinkUrl) return null
  setDynamicLinkUrl(dynamicLinkUrl)

  if (!plan.guestCanInvite) return null
  if (!plan.currentUserRsvp || [RsvpType.Declined, RsvpType.No, RsvpType.Pending].includes(plan.currentUserRsvp))
    return null
  if (plan.capacity?.atCapacity) return null

  const copyLinkDesktop = async () => {
    setDesktopCopyLinkButtonText('Copied!')
    navigator.clipboard.writeText(dynamicLinkUrl)
    setTimeout(() => {
      setDesktopCopyLinkButtonText('Copy link')
    }, 3000)
    createInviteFriendsClickedEvent(plan, user?.id)
  }

  // fail-safe if native share sheet can't be opened
  const copyLinkMobile = async () => {
    setMobileCopyLinkButtonText('Link copied!')
    navigator.clipboard.writeText(dynamicLinkUrl)
    setTimeout(() => {
      setMobileCopyLinkButtonText('Invite Friends')
    }, 3000)
  }

  const openMobileShareSheet = () => {
    try {
      navigator.share({
        title: plan.title,
        url: dynamicLinkUrl,
      })
    } catch (err) {
      copyLinkMobile()
    }
    createInviteFriendsClickedEvent(plan, user?.id)
  }

  const subtitleCopy = () => {
    if (plan.capacity?.maxCapacity) {
      const availableSpots = plan.capacity.maxCapacity - plan.capacity.currentCapacity
      return `RSVP required to join plan. ${availableSpots} ${availableSpots === 1 ? 'spot' : 'spots'} available!`
    } else {
      return 'RSVP required to join plan.'
    }
  }

  if (plan.ownerUserId === user.id) return null
  if (isMobile) {
    return (
      <div className="ChannelInviteFriendsCallout Flex Flex--col Flex--align-center P-6 My-6 G-3">
        <img className="ChannelInviteFriendsCallout__icon" src={PartyPopper} />
        <h3 className="Text-centered Blueberry-text">
          This Pie Plan is open invite,
          <br />
          tell your friends!
        </h3>
        <p className="Mb-3 Gray-text Text-centered">{subtitleCopy()}</p>
        <button
          className="ChannelInviteFriendsCallout__share__button Flex Flex--align-center Flex--centered Button-primary Button-medium"
          onClick={openMobileShareSheet}
        >
          {loading && <LoaderOnly className="ChannelInviteFriendsButton__button__loader" />}
          {!loading && <i className="bi bi-person-add Mr-1" />}
          {!loading && <p>{mobileInviteFriendsButtonText}</p>}
        </button>
      </div>
    )
  } else {
    return (
      <div className="ChannelInviteFriendsCallout Flex Flex--col Flex--align-center P-6 My-6">
        <img className="ChannelInviteFriendsCallout__icon" src={PartyPopper} />
        <h3 className="Text-centered Blueberry-text">
          This Pie Plan is open invite,
          <br />
          tell your friends!
        </h3>
        <p className="Mb-3 Gray-text">{subtitleCopy()}</p>
        <h4 className="Mb-2">Send them this invite link</h4>
        <div className="ChannelInviteFriendsCallout__share Flex Flex--align-center G-2">
          <div className="ChannelInviteFriendsCallout__share__link Flex Flex--align-center My-1 Px-3">
            <p className="ChannelInviteFriendsCallout__share__link__text">{dynamicLinkUrl}</p>
          </div>
          <button
            className="ChannelInviteFriendsCallout__share__button Flex Flex--align-center Flex--centered Button-primary Button-medium"
            onClick={copyLinkDesktop}
          >
            {loading && <LoaderOnly className="ChannelInviteFriendsCallout__share__button__loader" />}
            {!loading && <p>{desktopCopyLinkButtonText}</p>}
          </button>
        </div>
      </div>
    )
  }
}
