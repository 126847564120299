import Header from '../Header/Header'
import { PlanList } from '../PlanList/PlanList'
import { useSelector } from 'react-redux'
import { User } from '../../__generated__/graphql'
import { TermsUpdate } from '../TermsUpdate/TermsUpdate'
import { showTerms } from '../../constants/constants'
import { SignUpOrLogInPage } from '../SignUpOrLogInPage/SignUpOrLogInPage'
import { PlanListTabNav } from './PlanListTabNav/PlanListTabNav'

export const PlanListPage = () => {
  const user = useSelector((state: any) => state.user as User)
  const userLoaded = useSelector((state: any) => state.userLoaded as boolean)

  if (!userLoaded) {
    return null
  }

  if (!user?.id || !user.phoneNumber || !user.fullName || !user.imageUrl) {
    return <SignUpOrLogInPage />
  }

  // TODO: Display terms update modal if required
  //  and decide whether we need to use LaunchDarkly
  const termsUpdateModalRequired = user.tosAgreedVersion !== 1
  if (showTerms && termsUpdateModalRequired) {
    return (
      <>
        <Header />
        <TermsUpdate />
      </>
    )
  }

  return (
    <div className="PlanListPage Flex Flex--col Flex--align-stretch">
      <Header />
      <PlanListTabNav />
      <PlanList />
    </div>
  )
}
