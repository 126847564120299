import React from 'react'
import ThreeStrokes from '../../../assets/svg/ThreeStrokes.svg'
import { Plan } from '../../../__generated__/graphql'

interface HeaderProps {
  plan: Plan | null
}

function Header({ plan }: HeaderProps) {
  return (
    <header className="EnhancedAuth__header">
      <div className="EnhancedAuth__rock_on">rock on!</div>
      <img src={ThreeStrokes} alt="three brush strokes" />
      <div className="EnhancedAuth__rsvp">
        {plan ? (
          <div>to rsvp and get all the {plan.title} important updates, create your pie account.</div>
        ) : (
          <div>create or log in to your pie account.</div>
        )}
      </div>
    </header>
  )
}

export default Header
