import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { planUsersGenerateShareLinks } from '../graphql/mutations/plan-users-generate-share-links'
import { planIdToChannelId } from '../util/plan-id-to-channel-id'
import { useSelector } from 'react-redux'

export const useGenerateShareLinks = (planId: string) => {
  const [generateShareLinksMutation, { loading, data, error }] = useMutation(planUsersGenerateShareLinks)
  const authToken = useSelector((state: any) => state.authToken as string)

  useEffect(() => {
    if (authToken) {
      generateShareLinksMutation({ variables: { channelId: planIdToChannelId(planId) } }).catch((err) => {
        console.error('Error with generateShareLinksMutation', loading, data, error, err)
      })
    }
  }, [authToken])

  return { loading, data, error }
}
