import { useQuery } from '@apollo/client'
import { userConnectionsFindMutualFriends } from '../../../graphql/queries/user-connections-find-mutual-friends'
import './MutualFriends.scss'
import { UserConnectionsQueries } from '../../../__generated__/graphql'
import { SkeletonLoaderMutuals } from '../../SkeletonLoaderMutuals/SkeletonLoaderMutuals'

export const MutualFriends = ({ userId }: { userId: string }) => {
  const { loading, error, data } = useQuery<{ userConnections: UserConnectionsQueries }>(
    userConnectionsFindMutualFriends,
    {
      variables: { otherUserId: userId },
    },
  )

  if (loading) return <SkeletonLoaderMutuals />

  if (error || !data || !data.userConnections.findMutualFriends.items.length) return null

  const mutualFriends = data.userConnections.findMutualFriends.items

  if (mutualFriends.length === 0) return null

  return (
    <>
      <hr />
      <div className="MutualFriends">
        <h2>Mutuals</h2>
        <ul className="MutualFriends__list">
          {mutualFriends.map((friend, i) => (
            <li className="MutualFriends__list__item Flex Flex--col Flex--align-center Mr-3 Mb-3" key={i}>
              <img className="MutualFriends__list__item__image" src={friend.imageUrl || ''} alt="profile" />
              <p className="Mt-2 Subtitle">{friend.firstName}</p>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}
