import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getAuth } from '@firebase/auth'

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_HOST}/graphql`,
})

const authLink = setContext(async (_, { headers }) => {
  const auth = getAuth()
  const authToken = await auth.currentUser?.getIdToken()
  return {
    headers: {
      ...headers,
      authorization: authToken ? `Bearer ${authToken}` : '',
      'x-app-version': '4.2.0',
    },
  }
})

export const apolloClient = new ApolloClient({
  // uri: `${process.env.REACT_APP_API_HOST}/graphql`,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})
