import { ImageSizerInput } from '../types/image-sizer-input.interface'

export function imageSizer(input: ImageSizerInput): string {
  let url = input.url + '?optimize=low'
  if (input.width) {
    url += `&width=${input.width}`
  }
  if (input.height) {
    url += `&height=${input.height}`
  }
  return url
}
