import { useMutation } from '@apollo/client'
import { useSelector } from 'react-redux'
import { User, PlanDate as PlanDateType, CastDateVoteInput, Plan } from '../../../../__generated__/graphql'
import { channelsCastDateVote } from '../../../../graphql/mutations/channels-cast-date-vote'
import { getPlan } from '../../../../graphql/queries/plans-plan'
import { getDatePart } from '../../../../util/get-date-part'
import { getStartTime } from '../../../../util/get-start-time'
import { getTimezoneText } from '../../../../util/get-timezone-text'
import './PlanDatePoll.scss'
import { useState, useEffect } from 'react'

export const PlanDatePoll = ({
  inPlan,
  setSignUpAndRsvpModalIsOpen,
}: {
  inPlan: Plan
  setSignUpAndRsvpModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  // allow channel to be mutable component state for optimistic loading.
  // if inPlan prop changes, always reset channel to that.
  const [plan, setPlan] = useState(inPlan)
  useEffect(() => {
    setPlan(inPlan)
  }, [inPlan])
  const dates: PlanDateType[] = plan.dates ? [...plan.dates] : []

  const authToken = useSelector((state: any) => state.authToken)
  const user = useSelector((state: any) => state.user as User)

  dates.sort((a: PlanDateType, b: PlanDateType) => {
    return new Date(a.text).getTime() - new Date(b.text).getTime()
  })

  let timeText = (plan.startTimeText && getStartTime(plan.startTimeText)) || null
  if (dates.length === 1 && timeText && plan.timezone) {
    const timezoneText = getTimezoneText(plan.timezone)
    if (timezoneText) {
      timeText = `${timeText} ${timezoneText}`
    }
  }

  const [channelsCastDateVoteMutation] = useMutation<{
    channelsCastDateVoteInput: CastDateVoteInput
  }>(channelsCastDateVote, { refetchQueries: [getPlan] })

  const castVote = (dateText: string) => {
    // prompt sign up
    if (!authToken) {
      // this condition only occurs if the token expires, so this is probably a edge case, the vote would not be cast
      setSignUpAndRsvpModalIsOpen(true)
    } else {
      channelsCastDateVoteMutation({ variables: { channelId: plan.channelId, dateText } })
      // optimistically update channel
      const planCopy = JSON.parse(JSON.stringify(plan))
      if (planCopy && planCopy.dates) {
        const votedDate = planCopy.dates.find((date: PlanDateType) => date.text === dateText)
        if (votedDate?.voteUserIds.includes(user.id)) {
          votedDate.voteUserIds = votedDate.voteUserIds.filter((userId: string) => userId !== user.id)
        } else {
          votedDate?.voteUserIds.push(user.id)
        }
        setPlan(planCopy)
      }
    }
  }

  const dateHasMyVote = (date: PlanDateType) => {
    return date.voteUserIds.includes(user?.id)
  }

  return (
    <>
      <h3 className="PlanDatePoll__header">Choose a date</h3>
      <div className="PlanDatePoll__container">
        {dates?.map((date: PlanDateType, i: number) => (
          <div key={i}>
            <div
              className="PlanDatePoll__container__widget PlanDatePoll__container__widget--selectable"
              onClick={() => castVote(date.text)}
            >
              <div className="PlanDatePoll__container__widget__header">
                <div className="PlanDatePoll__container__widget__header__month">{getDatePart(date.text, 'MMM')}</div>
              </div>
              <div className="PlanDatePoll__container__widget__body">
                <div className="PlanDatePoll__container__widget__body__day-of-month">{getDatePart(date.text, 'D')}</div>
                <div className="PlanDatePoll__container__widget__body__day-of-week">
                  {getDatePart(date.text, 'ddd')}
                </div>
              </div>
            </div>
            <div
              onClick={() => castVote(date.text)}
              className={
                dateHasMyVote(date)
                  ? 'PlanDatePoll__container__votes PlanDatePoll__container__votes--selected'
                  : 'PlanDatePoll__container__votes'
              }
            >
              <p className="PlanDatePoll__container__votes__text">
                {date.voteUserIds?.length || 0}
                {date.voteUserIds?.length > 0 ? ' ☝️' : ''}
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
