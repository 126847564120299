import './EditFavoriteSpots.scss'
import { EditFavoriteSpot } from './EditFavoriteSpot/EditFavoriteSpot'
import { CreateFavoriteSpotInput, FavoriteSpotCategory, Place } from '../../../../__generated__/graphql'
import { SetStateAction, useEffect, useRef } from 'react'
import { InfoType } from '../../Profile/Profile'

export const EditFavoriteSpots = ({
  favoriteSpots,
  setFavoriteSpots,
  infoType,
}: {
  favoriteSpots: CreateFavoriteSpotInput[]
  setFavoriteSpots: (value: SetStateAction<CreateFavoriteSpotInput[]>) => void
  infoType: InfoType
}) => {
  const setSpot = (category: FavoriteSpotCategory | null, place: Place | null) => {
    const index = favoriteSpots.findIndex((fs) => fs.category === category)
    const updatedFavoriteSpots = [...favoriteSpots]
    if (index !== -1) {
      if (place) {
        updatedFavoriteSpots[index] = {
          placeId: place.placeId,
          venue: place.venue,
          address: place.address,
          coordinates: place.coordinates ? { lat: place.coordinates.lat, lng: place.coordinates.lng } : undefined,
          generalLocation: place.generalLocation,
          category: category,
        }
      } else {
        updatedFavoriteSpots.splice(index, 1)
      }
    } else if (place) {
      updatedFavoriteSpots.push({
        placeId: place.placeId,
        venue: place.venue,
        address: place.address,
        coordinates: place.coordinates ? { lat: place.coordinates.lat, lng: place.coordinates.lng } : undefined,
        generalLocation: place.generalLocation,
        category: category,
      })
    }
    setFavoriteSpots(updatedFavoriteSpots)
  }

  let favoriteCafe: CreateFavoriteSpotInput | undefined = favoriteSpots.find(
    (fs) => fs.category === FavoriteSpotCategory.Cafe,
  )
  let favoriteBar: CreateFavoriteSpotInput | undefined = favoriteSpots.find(
    (fs) => fs.category === FavoriteSpotCategory.Bar,
  )
  let favoriteRestaurant: CreateFavoriteSpotInput | undefined = favoriteSpots.find(
    (fs) => fs.category === FavoriteSpotCategory.Restaurant,
  )
  let favoritePark: CreateFavoriteSpotInput | undefined = favoriteSpots.find(
    (fs) => fs.category === FavoriteSpotCategory.Park,
  )
  let favoriteOther: CreateFavoriteSpotInput | undefined = favoriteSpots.find((fs) => fs.category === null)

  useEffect(() => {
    favoriteCafe = favoriteSpots.find((fs) => fs.category === FavoriteSpotCategory.Cafe)
    favoriteBar = favoriteSpots.find((fs) => fs.category === FavoriteSpotCategory.Bar)
    favoriteRestaurant = favoriteSpots.find((fs) => fs.category === FavoriteSpotCategory.Restaurant)
    favoritePark = favoriteSpots.find((fs) => fs.category === FavoriteSpotCategory.Park)
    favoriteOther = favoriteSpots.find((fs) => fs.category === null)
  }, [favoriteSpots])

  const favSpotsRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (infoType === 'favoriteSpots') {
      favSpotsRef.current?.scrollIntoView({ behavior: 'auto' })
    }
  }, [infoType])

  return (
    <div className="EditFavoriteSpots Flex Flex--col" ref={favSpotsRef}>
      <EditFavoriteSpot category={FavoriteSpotCategory.Cafe} spot={favoriteCafe} setSpot={setSpot} />
      <EditFavoriteSpot category={FavoriteSpotCategory.Bar} spot={favoriteBar} setSpot={setSpot} />
      <EditFavoriteSpot category={FavoriteSpotCategory.Restaurant} spot={favoriteRestaurant} setSpot={setSpot} />
      <EditFavoriteSpot category={FavoriteSpotCategory.Park} spot={favoritePark} setSpot={setSpot} />
      <EditFavoriteSpot category={null} spot={favoriteOther} setSpot={setSpot} />
    </div>
  )
}
