import { useQuery } from '@apollo/client'
import { pollsFind } from '../../../graphql/queries/polls'
import { Plan, PollsQueries } from '../../../__generated__/graphql'
import { Poll } from './Poll/Poll'
import Loader from '../../Loader/Loader'
import { PlanDatePoll } from '../ChannelDates/PlanDatePoll/PlanDatePoll'

export const ChannelPolls = ({
  plan,
  setSignUpAndRsvpModalIsOpen,
}: {
  plan: Plan
  setSignUpAndRsvpModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const {
    data,
    loading: pollLoading,
    error,
  } = useQuery<{ polls: PollsQueries }>(pollsFind, {
    variables: { planId: plan.id },
  })

  const dates = plan.dates || []

  if (pollLoading) return <Loader />

  if (error || !data || (!data.polls.find.length && dates.length <= 1)) return null

  return (
    <>
      <hr />
      <h2>Polls</h2>
      {dates && dates.length > 1 && (
        <PlanDatePoll setSignUpAndRsvpModalIsOpen={setSignUpAndRsvpModalIsOpen} inPlan={plan} />
      )}
      {data && data.polls.find.map((poll, i) => <Poll key={i} poll={poll} />)}
    </>
  )
}
