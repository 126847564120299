import PieLogo from '../../assets/svg/PieLogo.svg'
import './SignUpOrLoginPage.scss'
import { AuthType } from '../../util/auth-type.enum'
import { Auth } from '../Auth/Auth'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { feedInvitedPathname, signUpOrLogInPathname } from '../../constants/path-names'
import { useSelector } from 'react-redux'
import { User } from '../../__generated__/graphql'
import Header from '../Header/Header'
import { enableEnhancedOnboarding } from '../../constants/constants'
import { EnhancedAuth } from '../EnhancedAuth/EnhancedAuth'

export function SignUpOrLogInPage() {
  const navigate = useNavigate()
  const location = useLocation()

  const user = useSelector((state: any) => state.user as User)
  const [authType, setAuthType] = useState<AuthType | null>(location.state?.authType || null)

  if (user?.id && user.phoneNumber && user.fullName && user.imageUrl) {
    if (location.state?.redirectTo === signUpOrLogInPathname) {
      console.warn('Attempted to redirectTo login after completing login, sending to /feed', { state: location.state })
      navigate(feedInvitedPathname)
    } else {
      navigate(location.state?.redirectTo || feedInvitedPathname)
    }
  }

  if (enableEnhancedOnboarding) {
    return (
      <div className="Flex Flex--col Flex--align-stretch">
        <Header />
        <div className="Enhanced-card Enhanced-Auth-card">
          <div className="Auth-wrapper P-12">
            <EnhancedAuth plan={null} />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="Flex Flex--col Flex--align-stretch">
        <Header />
        <div className="Card Auth-card">
          <div className="Auth-wrapper P-12">
            {authType === AuthType.signIn && <Auth headerIcon={'✨'} headerText={'Sign in with your phone number'} />}
            {authType === AuthType.signUp && <Auth headerIcon={'✨'} headerText={'Sign up with your phone number'} />}
            {authType === null && (
              <div className="SignUpOrLogIn Flex Flex--col">
                <img className="SignUpOrLogIn__pie-logo" src={PieLogo} alt="Pie Logo" />
                <h1 className="SignUpOrLogIn__header">Turn ideas into plans with your people</h1>
                <button onClick={() => setAuthType(AuthType.signUp)} className="Button-primary Button-large Fill-width">
                  Sign Up
                </button>
                <button
                  onClick={() => setAuthType(AuthType.signIn)}
                  className="Button-secondary Button-large Fill-width"
                >
                  Log In
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
