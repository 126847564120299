import { Plan, RsvpType } from '../../../__generated__/graphql'
import './ChannelRsvpButtons.scss'

const ChannelRsvpButtons = ({ plan, onClick }: { plan: Plan; onClick: (rsvp: RsvpType) => void }) => {
  if (
    plan.enableRequestToJoin &&
    (plan.currentUserRsvp === RsvpType.Pending ||
      plan.currentUserRsvp === RsvpType.Accepted ||
      plan.currentUserRsvp === RsvpType.Declined)
  ) {
    return null
  }

  return (
    <div className="ChannelRsvpButtons">
      {!plan.enableRequestToJoin && <h3>RSVP</h3>}
      {(!plan.currentUserRsvp || plan.currentUserRsvp === RsvpType.Invited) && !!plan.capacity?.maxCapacity && (
        <p className="Pink-text Small-font">
          {plan.capacity.maxCapacity - plan.capacity.currentCapacity} spots available!
        </p>
      )}
      <div className="ChannelRsvpButtons__container Mt-3">
        {plan.enableRequestToJoin && (
          <button
            className={`Button-primary Button-medium Flex Flex--align-center Flex--centered Fill-width--mobile`}
            onClick={() => onClick(RsvpType.Pending)}
          >
            Request to Join
          </button>
        )}
        {!plan.enableRequestToJoin && (
          <>
            <button
              className={`ChannelRsvpButtons__container__button Flex Flex--align-center Flex--centered ${
                plan.currentUserRsvp === RsvpType.Yes ? 'ChannelRsvpButtons__container__button--selected' : ''
              }`}
              onClick={() => onClick(RsvpType.Yes)}
            >
              {plan.currentUserRsvp === RsvpType.Yes && (
                <i className="ChannelRsvpButtons__container__button__icon bi bi-check"></i>
              )}
              Yes
            </button>
            {!plan.capacity?.maxCapacity && (
              <button
                className={`ChannelRsvpButtons__container__button Flex Flex--align-center Flex--centered ${
                  plan.currentUserRsvp === RsvpType.Maybe ? 'ChannelRsvpButtons__container__button--selected' : ''
                }`}
                onClick={() => onClick(RsvpType.Maybe)}
              >
                {plan.currentUserRsvp === RsvpType.Maybe && (
                  <i className="ChannelRsvpButtons__container__button__icon bi bi-check"></i>
                )}
                Maybe
              </button>
            )}
            <button
              className={`ChannelRsvpButtons__container__button Flex Flex--align-center Flex--centered ${
                plan.currentUserRsvp === RsvpType.No ? 'ChannelRsvpButtons__container__button--selected' : ''
              }`}
              onClick={() => onClick(RsvpType.No)}
            >
              {plan.currentUserRsvp === RsvpType.No && (
                <i className="ChannelRsvpButtons__container__button__icon bi bi-check"></i>
              )}
              No
            </button>
          </>
        )}
      </div>
    </div>
  )
}

export { ChannelRsvpButtons }
