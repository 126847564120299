import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const SkeletonLoaderMutuals = () => {
  return (
    <div>
      <hr />
      <h2 className="Mt-6 Mb-2">
        <Skeleton borderRadius="1.25rem" width="140px" />
      </h2>
      <div className="Flex">
        <div className="Mr-2">
          <Skeleton count={1} circle width="56px" height="56px" />
        </div>
        <div className="Mr-2">
          <Skeleton count={1} circle width="56px" height="56px" />
        </div>
        <div className="Mr-2">
          <Skeleton count={1} circle width="56px" height="56px" />
        </div>
        <div className="Mr-2">
          <Skeleton count={1} circle width="56px" height="56px" />
        </div>
      </div>
    </div>
  )
}
