import { useQuery } from '@apollo/client'
import { Plan, PlansQueries, User } from '../../../__generated__/graphql'
import { PlanCard } from '../../PlanCard/PlanCard'
import Loader from '../../Loader/Loader'
import Error from '../../Error/Error'
import { plansFindPlanForUser } from '../../../graphql/queries/plans-find-plan-for-user'
import { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { SkeletonLoaderPlanCard } from '../../SkeletonLoaderPlanCard/SkeletonLoaderPlanCard'

export const ProfileUserPlans = ({ userProfile }: { userProfile: User }) => {
  const [plans, setPlans] = useState([] as Plan[])
  const [hasMore, setHasMore] = useState(false)
  const [offset, setOffset] = useState(0)
  const limit = 10

  const { error, loading, fetchMore } = useQuery<{ plans: PlansQueries }>(plansFindPlanForUser, {
    variables: {
      otherUserId: userProfile.id,
      limit,
      offset,
    },
    onCompleted: (data) => {
      if (offset === 0) {
        setPlans(data.plans.findPlansForUser.items)
        setHasMore(data.plans.findPlansForUser.hasMore)
        setOffset(offset + limit)
      }
    },
  })

  if (error) {
    console.error('Unexpected plans FindPlanForUser response', {
      otherUserId: userProfile.id,
      limit,
      offset,
      hasMore,
      plans,
      error,
    })
    return <Error />
  }

  if (loading && offset === 0) return <SkeletonLoaderPlanCard />

  const fetchMorePlans = async () => {
    const { data } = await fetchMore({ variables: { otherUserId: userProfile.id, limit, offset } })
    setPlans((prevPlans) => [...prevPlans, ...data.plans.findPlansForUser.items])
    setHasMore(data.plans.findPlansForUser.hasMore)
    setOffset(offset + limit)
  }

  if (plans.length === 0) {
    return null
  }

  return (
    <>
      <hr />
      <h2 className="Mb-2">Plans they’ve joined</h2>
      <InfiniteScroll dataLength={plans.length} next={fetchMorePlans} hasMore={hasMore} loader={<Loader />}>
        {plans.map((plan) => (
          <PlanCard plan={plan} key={plan.id} isMorePlansSection={false} />
        ))}
      </InfiniteScroll>
    </>
  )
}
