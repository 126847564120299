import { User, UserConnectionStatus } from '../../../__generated__/graphql'
import { ProfileUserPlans } from './ProfileUserPlans'
import { ProfileSharedPlans } from './ProfileSharedPlans'

export const ProfileJoinedPlans = ({ userProfile }: { userProfile: User }) => {
  const userConnectionStatus = userProfile.userConnectionStatus

  if (
    userConnectionStatus === UserConnectionStatus.AddedMe ||
    userConnectionStatus === UserConnectionStatus.BothAdded
  ) {
    return <ProfileUserPlans userProfile={userProfile} />
  } else {
    return <ProfileSharedPlans userProfile={userProfile} />
  }
}
