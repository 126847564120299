import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { StreamChat } from 'stream-chat'
import { streamApiKey } from '../../constants/constants'
import axios from 'axios'
import { store } from '../../store/store'

export function InitializeStreamChat(): null {
  const streamChatInitialized = useSelector((state: any) => {
    return state.streamChatInitialized
  })
  const authToken = useSelector((state: any) => state.authToken as string)
  const userId = useSelector((state: any) => state.user?.id as string)
  useEffect(() => {
    // NOTE: using getInstance ensures we only create a client once
    const client = StreamChat.getInstance(`${streamApiKey}`)
    if (client && authToken && userId && !streamChatInitialized) {
      const connectUser = async () => {
        await client.connectUser({ id: userId }, async () => {
          const response = await axios.post(
            `${process.env.REACT_APP_API_HOST}/chat-tokens`,
            {},
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: authToken ? `Bearer ${authToken}` : '',
              },
            },
          )
          store.dispatch({ type: 'chat/setToken', chatToken: response.data.token })
          return response.data.token
        })
      }
      connectUser()
        .then(() => {
          store.dispatch({ type: 'streamChat/setInitialized', initialized: true })
        })
        .catch((err) => {
          console.error('Error connecting user to stream chat client', err)
        })
    }
  }, [authToken, userId, streamChatInitialized])
  return null
}
