import { PinnedMessage } from '../../../__generated__/graphql'
import { imageSizer } from '../../../util/image-sizer'
import './ChannelPinnedMessages.scss'

const ChannelPinnedMessages = ({ pinnedMessages }: { pinnedMessages: PinnedMessage[] }) => {
  if (!pinnedMessages || !pinnedMessages.length) return null

  return (
    <div className="Pinned-messages">
      <hr />

      <div className="Pinned-messages-header-container">
        <h2>Pinned messages</h2>
      </div>

      {pinnedMessages.map((pinnedMessage: PinnedMessage, i: number) => (
        <div className="Pinned-messages-body-container" key={i}>
          {/* TODO: handle null profile image */}
          {pinnedMessage.text && (
            <>
              <img
                className="Pinned-messages-user-image"
                src={
                  pinnedMessage.user.imageUrl ? imageSizer({ url: pinnedMessage.user.imageUrl, width: 300 }) : undefined
                }
              />
              <p>{pinnedMessage.text}</p>
            </>
          )}
        </div>
      ))}
    </div>
  )
}

export { ChannelPinnedMessages }
