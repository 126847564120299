import { gql } from '@apollo/client'

export const channelsCastDateVote = gql`
  mutation channelsCastDateVote($channelId: String!, $dateText: String!) {
    channels {
      castDateVote(id: $channelId, input: { dateText: $dateText }) {
        cid
      }
    }
  }
`
