import { gql } from '@apollo/client'

export const user = gql`
  query user($userId: String!) {
    users {
      user(id: $userId) {
        id
        firstName
        lastName
        fullName
        imageUrl
        bio
        instagram
        twitter
        linkedIn
        neighborhood
        city
        state
        country
        userConnectionStatus
        userInterests {
          interest {
            id
            name
          }
        }
        favoriteSpots {
          id
          placeId
          venue
          address
          coordinates {
            lat
            lng
          }
          category
          generalLocation
          emoji
        }
      }
    }
  }
`
