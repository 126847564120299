import { Dispatch, SetStateAction, useState } from 'react'
import { InterestsPill } from './InterestsPill'
import './InterestsPills.scss'

export type Interest = {
  id: string
  name: string
  didSelect: boolean
}

export type InterestsSelectorProps = {
  interests: Interest[]
  onInterestToggle: (id: string) => void
}

export const InterestsPills = ({
  interests,
  isDisabled,
  isOnboarding,
  setInterestsSelected,
}: {
  interests: Interest[]
  isDisabled?: boolean
  isOnboarding?: boolean
  setInterestsSelected?: Dispatch<SetStateAction<string[]>>
}) => {
  const [newInterests, setNewInterests] = useState<Interest[]>(interests)

  const onInterestToggle = (id: string) => {
    const interestIndex = newInterests.findIndex((interest) => interest.id === id)
    const interest = newInterests[interestIndex]
    const newInterestsCopy = [...newInterests]
    newInterestsCopy[interestIndex] = {
      ...interest,
      didSelect: !interest.didSelect,
    }
    setNewInterests([...newInterestsCopy])
    if (setInterestsSelected) {
      setInterestsSelected(newInterestsCopy.filter((interest) => interest.didSelect).map((interest) => interest.id))
    }
  }

  return (
    <div className={`InterestsPills ${isOnboarding ? 'Flex--centered' : ''}`}>
      {newInterests.map((interests: Interest) => (
        <InterestsPill
          key={interests.id}
          interest={interests}
          onInterestToggle={onInterestToggle}
          isDisabled={isDisabled}
        />
      ))}
    </div>
  )
}
