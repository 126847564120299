import { datadogLogs } from '@datadog/browser-logs'

export function initDdLogs() {
  if (process.env.REACT_APP_DD_LOGGER_TOKEN) {
    datadogLogs.init({
      proxy: `${process.env.REACT_APP_API_HOST}/dd/logs`,
      clientToken: process.env.REACT_APP_DD_LOGGER_TOKEN,
      site: process.env.REACT_APP_DD_SITE,
      forwardConsoleLogs: 'all',
      forwardErrorsToLogs: false,
      sessionSampleRate: 100,
      service: process.env.REACT_APP_DD_SERVICE,
      env: process.env.REACT_APP_DD_ENV,
      version: '1.0.0',
    })
  }
}
