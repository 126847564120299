import { gql } from '@apollo/client'

export const plansFindPlanForUser = gql`
  query plansFindPlanForUser($otherUserId: String!, $limit: Int, $offset: Int) {
    plans {
      findPlansForUser(otherUserId: $otherUserId, limit: $limit, offset: $offset) {
        total
        hasMore
        items {
          id
          title
          ownerUserId
          ownerUser {
            firstName
          }
          type
          isVisibilityOpen
          timezone
          channelId
          dates {
            text
            voteUserIds
          }
          startTimeText
          endTimeText
          imageUrl
          planMembers(limit: 3) {
            total
            items {
              user {
                fullName
                imageUrl
              }
            }
          }
        }
      }
    }
  }
`
