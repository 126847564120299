import { useSelector } from 'react-redux'
import { PollOption } from '../../../../__generated__/graphql'
import './ResultsPollOption.scss'

export const ResultsPollOption = ({ option, totalVotesSum }: { option: PollOption; totalVotesSum: number }) => {
  const votes = option.votes?.length
  const isWinner = option.isWinner

  const isMobile = useSelector((state: any) => {
    return state.isMobile
  })

  return (
    <div className="ResultsPollOption">
      <div className="ResultsPollOption__votes">
        <div className="ResultsPollOption__votes-value">{option.value}</div>
        <div>
          <div
            className={
              isWinner
                ? 'ResultsPollOption__votes-number ResultsPollOption__votes-number--winner'
                : 'ResultsPollOption__votes-number'
            }
          >
            {votes !== 1 && (
              <div className="ResultsPollOption__votes-number-flex">
                <p>{votes}</p> {!isMobile && <p className="ResultsPollOption__votes-number-text">votes</p>}
              </div>
            )}
            {votes === 1 && (
              <div className="ResultsPollOption__votes-number-flex">
                <p>{votes}</p> {!isMobile && <p className="ResultsPollOption__votes-number-text">vote</p>}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="ResultsPollOption__progress-container">
        <div
          className={
            isWinner
              ? 'ResultsPollOption__progress-bar ResultsPollOption__progress-bar--winner'
              : 'ResultsPollOption__progress-bar'
          }
          style={{ width: `${(votes / totalVotesSum) * 100}%` }}
        >
          {' '}
        </div>
      </div>
    </div>
  )
}
