import { gql } from '@apollo/client'

// Used for requesting to join plan
export const planUsersRequestToJoin = gql`
  mutation planUsersRequestToJoin($planId: String!) {
    planUsers {
      requestToJoin(input: { planId: $planId })
    }
  }
`
