import { useSelector } from 'react-redux'
import { store } from '../../store/store'
import { User, UsersMutations } from '../../__generated__/graphql'
import { useMutation } from '@apollo/client'
import { usersSignUp } from '../../graphql/mutations/users-sign-up'
import { useEffect } from 'react'
import { MixpanelEvent } from '../../types/mixpanel-event.enum'
import { createMixpanelEvent } from '../../util/create-mixpanel-event'

export function UpsertUser(): null {
  const authToken = useSelector((state: any) => {
    return state.authToken
  })
  const authTokenLoaded = useSelector((state: any) => {
    return state.authTokenLoaded
  })
  const user = useSelector((state: any) => {
    return state.user as User
  })

  const [signUpMutation] = useMutation<{ users: UsersMutations }>(usersSignUp)
  useEffect(() => {
    const signUp = async () => {
      if (authToken && !user?.id) {
        const response = await signUpMutation()
        store.dispatch({ type: 'user/upsert', user: response.data?.users.signUp || {} })
      } else if (authTokenLoaded) {
        store.dispatch({ type: 'user/setLoaded', loaded: true })
      }
    }
    signUp()
    createMixpanelEvent(MixpanelEvent.appSession)
  }, [authToken, user, authTokenLoaded])
  return null
}
