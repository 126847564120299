import type { JSX } from 'react'
import PieSlice from '../../../assets/svg/PieSlice.svg'

export function DidYouKnow(): JSX.Element {
  return (
    <div className="EnhancedAuth__additional_info">
      <div className="EnhancedAuth__additional_info_header">
        <img src={PieSlice} alt="image of a slice of pie" />
        <b>did you know?</b>
      </div>
      <p>
        you can find more great plans on pie & connect with others in your community.
        <br />
        add your name to show you're real!
      </p>
    </div>
  )
}
