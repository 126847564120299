import './TextArea.scss'

export const TextArea = ({
  className,
  placeHolder,
  error,
  errorText,
  value,
  autoFocus,
  onChange,
  onClick,
}: {
  className?: string
  placeHolder: string
  error?: boolean
  errorText?: string
  value: string
  autoFocus?: boolean
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>
  onClick?: React.MouseEventHandler<HTMLTextAreaElement>
}) => {
  return (
    <div className={`TextArea ${className}`}>
      <textarea
        className="TextArea__input"
        placeholder={placeHolder}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        onClick={onClick}
        required={error}
      />
      <label className="TextArea__label">{placeHolder}</label>
      {error && <div className="TextArea__error">{errorText}</div>}
    </div>
  )
}
