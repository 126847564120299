import type { JSX } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import './PlanDetailPage.scss'
import PlanDetail from '../Channel/PlanDetail'
import Loader from '../Loader/Loader'
import Header from '../Header/Header'
import Error from '../Error/Error'
import { MorePlans } from '../MorePlans/MorePlans'
import { useLazyQuery } from '@apollo/client'
import { PlansQueries } from '../../__generated__/graphql'
import { getPlan } from '../../graphql/queries/plans-plan'
import { MixpanelEvent } from '../../types/mixpanel-event.enum'
import { createMixpanelEvent } from '../../util/create-mixpanel-event'
import { channelIdToPlanId } from '../../util/channel-id-to-plan-id'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { feedJoinedPathname } from '../../constants/path-names'
import { ChannelChat } from '../ChannelChat/ChannelChat'
import { PreviousView } from '../../types/previous-view.enum'
import { STATUS_HTTP } from '../../constants/http'

export function PlanDetailPage(): JSX.Element {
  const refStatusFetchPlan = useRef(STATUS_HTTP.clean)
  const [isChatOpen, setIsChatOpen] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const routeParams = useParams()

  const searchParamChannelId = searchParams.get('id')
  const routeParamPlanId = routeParams.id
  const planId = channelIdToPlanId(searchParamChannelId || routeParamPlanId)

  const [fetchPlan, { loading, error, data }] = useLazyQuery<{ plans: PlansQueries }>(getPlan, {
    variables: { id: planId },
  })

  const handleChatOpen = useCallback(function _handleOpenChat() {
    setIsChatOpen(true)
  }, [])

  const handleChatClose = useCallback(function _handleOpenChat() {
    setIsChatOpen(false)
  }, [])

  const plan = data?.plans?.plan

  useEffect(() => {
    if (planId == null) {
      refStatusFetchPlan.current = STATUS_HTTP.done
      console.warn(`Expecting an 'id' url param...redirecting to the home page`, {
        searchParams,
        useParams: routeParams,
      })
      navigate(feedJoinedPathname)
    } else if (refStatusFetchPlan.current !== STATUS_HTTP.pending) {
      refStatusFetchPlan.current = STATUS_HTTP.pending
      fetchPlan({ variables: { id: planId } }).finally(() => {
        refStatusFetchPlan.current = STATUS_HTTP.done
      })
    }
  }, [fetchPlan, navigate, planId])

  const inviterPlanUserId = searchParams.get('inviter')
  useEffect(() => {
    if (planId != null && inviterPlanUserId) {
      const eventData = {
        inviterPlanUserId,
        planId,
        plan,
        planTitle: plan?.title,
      }
      createMixpanelEvent(MixpanelEvent.shareLinkOpened, eventData)
    }
  }, [inviterPlanUserId, plan, planId])

  useEffect(() => {
    if (planId != null) {
      const aPreviousView = location.state?.previousView
      const eventData = {
        planId,
        plan,
        planTitle: plan?.title,
        didNavigateFromMorePlansSection: aPreviousView === PreviousView.morePlansForYou,
        previousView: aPreviousView,
      }
      createMixpanelEvent(MixpanelEvent.viewPlan, eventData)
    }
  }, [location, plan, planId])

  const hasError = !planId || !plan || error

  return (
    <>
      {loading ? (
        <div className="Large-padding">
          <Loader />
        </div>
      ) : (
        <>
          <Header />
          {hasError ? (
            <Error />
          ) : isChatOpen ? (
            <ChannelChat plan={plan} isChatOpen={isChatOpen} handleChatBackButton={handleChatClose} />
          ) : (
            <div className="PlanDetailPage">
              <PlanDetail plan={plan} handleOpenChat={handleChatOpen} />
              <MorePlans plan={plan} />
            </div>
          )}
        </>
      )}
    </>
  )
}
