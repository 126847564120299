import './ButtonHide.scss'

export const ButtonHide = ({ style, onHideButtonClick }: { style?: any; onHideButtonClick?: any }) => {
  return (
    <div style={style}>
      <button
        className="Button-secondary Flex Flex--centered Flex--align-center ButtonHide"
        onClick={onHideButtonClick}
      >
        <i className="bi bi-x"></i>
      </button>
    </div>
  )
}
