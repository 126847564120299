import './ChannelPlace.scss'
// import { Plan, RsvpType } from '../../../__generated__/graphql'
import { Plan } from '../../../__generated__/graphql'

const ChannelPlace = ({ plan }: { plan: Plan }) => {
  // Location not available
  if (!plan.place) {
    return null
  }
  // let's disable this and see if it always displays locations
  // const didRsvpYesOrMaybe =
  //   plan.currentUserRsvp && [RsvpType.Yes, RsvpType.Maybe, RsvpType.Accepted].includes(plan.currentUserRsvp)
  //
  // // Location available, user not joined
  // if (!didRsvpYesOrMaybe) {
  //   return (
  //     <div className="ChannelPlace--no-location Flex G-3">
  //       <i className="bi bi-geo-alt ChannelPlace--no-location__icon"></i>
  //       <h5>RSVP to see location</h5>
  //     </div>
  //   )
  // }

  // Location available, user joined
  return (
    <div className="ChannelPlace">
      <a
        className="ChannelPlace__link"
        href={`https://maps.google.com/?q=${encodeURI(plan.place.venue + ' ' + plan.place.address)}`}
        target="_blank"
      >
        <i className="bi bi-geo-alt ChannelPlace__link__icon"></i>
        <div className="ChannelPlace__link__text">
          <h5>{plan.place.venue}</h5>
          <p className="ChannelPlace__link__text__address">{plan.place.address}</p>
        </div>
      </a>
    </div>
  )
}

export { ChannelPlace }
