import { Dispatch, SetStateAction } from 'react'
import './ChannelDates.scss'
import { Plan, PlanDate as PlanDateType, RsvpType } from '../../../__generated__/graphql'
import { getTimezoneText } from '../../../util/get-timezone-text'
import { getStartTime } from '../../../util/get-start-time'
import { PlanDate } from './PlanDate/PlanDate'
import { PlanTime } from './PlanTime/PlanTime'

export const ChannelDates = ({
  plan,
}: {
  plan: Plan
  setSignUpAndRsvpModalIsOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const dates: PlanDateType[] = plan.dates ? [...plan.dates] : []
  dates.sort((a: PlanDateType, b: PlanDateType) => {
    return new Date(a.text).getTime() - new Date(b.text).getTime()
  })
  const didRsvpYesOrMaybe =
    plan.currentUserRsvp && [RsvpType.Yes, RsvpType.Maybe, RsvpType.Accepted].includes(plan.currentUserRsvp)

  let timeText = (plan.startTimeText && getStartTime(plan.startTimeText)) || null
  if (dates.length === 1 && timeText && plan.timezone) {
    const timezoneText = getTimezoneText(plan.timezone)
    if (timezoneText) {
      timeText = `${timeText} ${timezoneText}`
    }
  }

  const invalidTimeText = timeText === 'Invalid Date' || timeText === null

  return (
    <div className="ChannelDates">
      {dates && dates.length > 1 && !didRsvpYesOrMaybe && (
        <div className="ChannelDates--no-location Flex G-3">
          <i className="bi bi-calendar ChannelDates--no-location__icon"></i>
          <h5>Join to vote on a date</h5>
        </div>
      )}

      {dates && dates.length === 1 && <PlanDate dates={dates} timeText={timeText} />}
      {timeText && !invalidTimeText && (dates.length === 0 || dates.length > 1) && <PlanTime timeText={timeText} />}
    </div>
  )
}
