import { gql } from '@apollo/client'

export const planUsersGenerateShareLinks = gql`
  mutation planUsersGenerateShareLinks($channelId: String!) {
    planUsers {
      generateShareLinks(input: { channelId: $channelId }) {
        dynamicLinkUrl
        dynamicLinkUrlWeb
      }
    }
  }
`
