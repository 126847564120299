import './GetThePieApp.scss'

import type { JSX } from 'react'

import { screenShotUrl } from '../../constants/constants'

export function GetThePieApp(): JSX.Element {
  return (
    <div className="GetThePieApp">
      <div className="GetThePieApp__info">
        <h2 className="GetThePieApp__info__title">Get the pie app</h2>
        <p>your social feed of things to do</p>
        <ul>
          <li>find unique plans near you</li>
          <li>see what you're missin' &#129488;</li>
          <li>bring your friends + and make new ones</li>
        </ul>
      </div>
      <div className="GetThePieApp__image">
        <img src={screenShotUrl} alt="Pie app screen shot" />
      </div>
    </div>
  )
}
