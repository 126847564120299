import { useState } from 'react'
import { StreamChat, QueryChannelAPIResponse, User } from 'stream-chat'
import { streamApiKey } from '../../../constants/constants'
import { useGetStreamChannel } from '../../../hooks/useGetStreamChannel'
import './ChannelChatButton.scss'
import { useSelector } from 'react-redux'
import { Plan } from '../../../__generated__/graphql'

export const ChannelChatButton = ({ handleOpenChat, plan }: { handleOpenChat: () => void; plan: Plan }) => {
  const user = useSelector((state: any) => state.user as User)

  // Stream chat
  const [unreadCount, setUnreadCount] = useState(0)
  const streamChatClient = StreamChat.getInstance(`${streamApiKey}`)
  const streamChannel = useGetStreamChannel(streamChatClient, plan, user?.id)
  if (streamChannel) {
    streamChannel
      .watch()
      .then((response: QueryChannelAPIResponse) => {
        const unreadCount = response.read?.find((item) => item.user.id === user?.id)?.unread_messages
        setUnreadCount(unreadCount || 0)
      })
      .catch((err) => {
        console.error('Error watching channel', err, { channelId: plan.channelId, userId: user?.id })
      })
  }

  return (
    <div className="ChannelChatButton">
      <button onClick={handleOpenChat} className="ChannelChatButton__button Button-large Button-primary">
        <i className="bi bi-chat ChannelChatButton__button__icon"></i>
        <p>Chat</p>
        {unreadCount > 0 && <div className="ChannelChatButton__button__badge"></div>}
      </button>
    </div>
  )
}
