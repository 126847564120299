import { gql } from '@apollo/client'

export const limitedUser = gql`
  query getLimitedUser($userId: String!) {
    limitedUser(id: $userId) {
      id
      firstName
      imageUrl
    }
  }
`
