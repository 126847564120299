import './PlanListTabNav.scss'
import { feedInvitedPathname, feedJoinedPathname } from '../../../constants/path-names'
import { Link } from 'react-router-dom'
import { createMixpanelEvent } from '../../../util/create-mixpanel-event'
import { MixpanelEvent } from '../../../types/mixpanel-event.enum'

export const PlanListTabNav = () => {
  const pathname = location.pathname
  const navItems = [
    { path: feedInvitedPathname, text: 'Invites', icon: 'bi bi-stars', isDefault: true },
    { path: feedJoinedPathname, text: 'Plans', icon: 'bi bi-calendar-check' },
  ]

  const onLinkClicked = (path: string) => {
    if (path === feedInvitedPathname) {
      createMixpanelEvent(MixpanelEvent.inviteTabClicked, {})
    }
  }

  return (
    <div className="PlanListTabNav Flex Flex--centered G-3">
      {navItems.map((navItem) => {
        return (
          <div key={navItem.path} className="PlanListTabNav__item">
            <Link
              onClick={() => {
                onLinkClicked(navItem.path)
              }}
              className="PlanListTabNav__item__link"
              to={navItem.path}
            >
              <div className="Flex G-2 Px-2 Py-3">
                <i className={`PlanListTabNav__item__link__icon ${navItem.icon}`}></i>
                <h3 className="PlanListTabNav__item__link__text">{navItem.text}</h3>
              </div>
              {pathname === navItem.path && <div className="PlanListTabNav__item__link__bottom-border"></div>}
              {pathname === '/' && navItem.isDefault && (
                <div className="PlanListTabNav__item__link__bottom-border"></div>
              )}
            </Link>
          </div>
        )
      })}
    </div>
  )
}
