import { AttachmentPreviewList, ChatAutoComplete, EmojiPicker, useMessageInputContext } from 'stream-chat-react'
import './CustomMessageInput.scss'
import Send from '../../assets/svg/Send.svg'
import EmojiIcon from '../../assets/svg/EmojiIcon.svg'
import Plus from '../../assets/svg/Plus.svg'
import { FileUploadButton } from 'react-file-utils'
import { useSelector } from 'react-redux'

export const CustomMessageInput = () => {
  const { closeEmojiPicker, emojiPickerIsOpen, handleEmojiKeyDown, handleSubmit, openEmojiPicker, uploadNewFiles } =
    useMessageInputContext()

  const isMobile = useSelector((state: any) => {
    return state.isMobile
  })
  return (
    <div className="Custom-message-input">
      <div className="Custom-message-input-textarea-wrapper">
        <FileUploadButton
          accepts="image/*"
          handleFiles={uploadNewFiles}
          multiple={false}
          children={
            <span className="Custom-message-input-attachment-button">
              <img src={Plus} alt="emoji-icon" />
            </span>
          }
        />
        <AttachmentPreviewList />
        <div className="Custom-message-input-emoji-wrapper">
          <ChatAutoComplete placeholder="Send a message" />
          {!isMobile && (
            <>
              <button
                className="Custom-message-input-emojiselect"
                onClick={emojiPickerIsOpen ? closeEmojiPicker : openEmojiPicker}
                onKeyDown={handleEmojiKeyDown}
                role="button"
                tabIndex={0}
              >
                <img className="Custom-message-input-emojiselect-img" src={EmojiIcon} alt="emoji-icon" />
              </button>
              <EmojiPicker />
            </>
          )}
        </div>
      </div>
      <button className="Custom-message-input-button" onClick={handleSubmit}>
        <img src={Send} alt="send-button" />
      </button>
    </div>
  )
}
