import { useLocation, useNavigate } from 'react-router-dom'
import {
  feedInvitedPathname,
  feedJoinedPathname,
  invitedPlanPrefix,
  planCreateOrEditPathname,
} from '../../constants/path-names'
import './ChannelNavButtons.scss'
import { PreviousView } from '../../types/previous-view.enum'
import { useState } from 'react'
import { createInviteFriendsClickedEvent } from '../Channel/ChannelInviteFriendsCallout/ChannelInviteFriendsCallout'
import { Plan, RsvpType, User } from '../../__generated__/graphql'
import { useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import { plansDelete } from '../../graphql/mutations/plans-delete'
import { PlanEditView } from '../../types/PlanEditView.enum'
import { ModalOrDrawer } from '../ModalOrDrawer/ModalOrDrawer'

export const ChannelNavButtons = ({
  plan,
  dynamicLinkUrl,
  isMyId,
  isBackButton,
  handleEditProfile,
}: {
  plan?: Plan
  dynamicLinkUrl?: string | null
  isMyId?: boolean
  isBackButton?: boolean
  handleEditProfile?: () => void
}) => {
  const location = useLocation()
  const previousView: PreviousView | undefined = location.state?.previousView
  const planId = plan?.id || location.state?.planId
  const navigate = useNavigate()
  const user = useSelector((state: any) => state.user as User)
  const isMini = useSelector((state: any) => {
    return state.isMini
  })

  const handleViewFeed = () => {
    if (previousView === PreviousView.planDetailPage && planId) {
      navigate(`/plans/${planId}`)
    } else {
      location.pathname.includes(invitedPlanPrefix) ? navigate(feedInvitedPathname) : navigate(feedJoinedPathname)
    }
  }

  const buttonText = location.pathname.includes(invitedPlanPrefix) ? 'Invites' : 'Plans'

  const [inviteFriendsButtonText, setInviteFriendsButtonText] = useState('Invite')

  const copyLink = async () => {
    setInviteFriendsButtonText('Link copied!')
    navigator.clipboard.writeText(dynamicLinkUrl || '')
    setTimeout(() => {
      setInviteFriendsButtonText('Invite')
    }, 3000)
  }

  const openMobileShareSheet = async () => {
    if (!plan) return
    if (!dynamicLinkUrl) return
    try {
      navigator.share({
        title: plan?.title,
        url: dynamicLinkUrl,
      })
    } catch (err) {
      // fail-safe if native share sheet can't be opened
      copyLink()
    }
    createInviteFriendsClickedEvent(plan, user?.id)
  }

  const handleEditPlanDetails = () => {
    navigate(planCreateOrEditPathname, {
      state: {
        previousView: PreviousView.planDetailPage,
        plan,
      },
    })
  }

  const handleEditPlanInviteSettings = () => {
    navigate(planCreateOrEditPathname, {
      state: {
        previousView: PreviousView.planDetailPage,
        plan,
        planEditView: PlanEditView.inviteSettings,
      },
    })
  }

  const [plansDeleteMutation] = useMutation(plansDelete, {
    variables: { id: planId },
  })

  const [deletePlanConfirmationIsOpen, setDeletePlanConfirmationIsOpen] = useState(false)

  const handleDeletePlan = async () => {
    await plansDeleteMutation()
    navigate(feedJoinedPathname)
  }

  const renderDeletePlanConfirmation = () => {
    const deletePlanConfirmationContent = (
      <div className="Flex Flex--col G-3 Flex--align-center">
        <h2>Delete this plan?</h2>
        <p className="Mb-8">{`"${plan?.title}" will be permanently deleted.`}</p>
        <button className="Button-primary Button-large Fill-width" onClick={handleDeletePlan}>
          Delete Plan
        </button>
        <button
          className="Button-secondary Button-large Fill-width"
          onClick={() => setDeletePlanConfirmationIsOpen(false)}
        >
          Cancel
        </button>
      </div>
    )
    if (deletePlanConfirmationIsOpen) {
      return (
        <ModalOrDrawer
          setIsOpen={setDeletePlanConfirmationIsOpen}
          content={deletePlanConfirmationContent}
          height={'auto'}
        />
      )
    }
    return null
  }

  return (
    <div className="ChannelNavButtons Flex Flex--end Flex--align-end">
      <button className="Button-small Button-secondary My-3 ChannelNavButtons__back-button" onClick={handleViewFeed}>
        <i className="bi bi-chevron-left Mr-1"></i>
        {isBackButton ? 'Back' : buttonText}
      </button>
      <div className="ChannelNavButtons__buttons-right Flex G-3">
        {isMyId && (
          <button
            className="Button-small Button-secondary My-3 ChannelNavButtons__edit-profile-button"
            onClick={handleEditProfile}
          >
            Edit Profile
          </button>
        )}
        {plan && plan.ownerUserId === user.id && (
          <>
            <button className="Button-small Button-secondary My-3" onClick={handleEditPlanDetails}>
              {isMini ? 'Edit' : 'Edit Details'}
            </button>
            <button
              className="ChannelNavButtons__buttons-right__settings-button Flex Flex--centered
               Flex--align-center Button-small Button-secondary My-3"
              onClick={handleEditPlanInviteSettings}
            >
              <i className="ChannelNavButtons__buttons-right__settings-button__icon bi bi-gear-wide-connected"></i>
            </button>
            <button
              className="ChannelNavButtons__buttons-right__delete-button Flex Flex--centered
               Flex--align-center Button-small Button-secondary My-3"
              onClick={() => setDeletePlanConfirmationIsOpen(true)}
            >
              <i className="ChannelNavButtons__buttons-right__delete-button__icon bi bi-trash3"></i>
            </button>
          </>
        )}
        {dynamicLinkUrl &&
          plan &&
          plan.guestCanInvite &&
          plan.currentUserRsvp &&
          [RsvpType.Yes, RsvpType.Maybe].includes(plan.currentUserRsvp) &&
          (!plan.capacity || !plan.capacity.atCapacity) &&
          plan.ownerUserId !== user.id && (
            <button className="Button-small Button-secondary My-3" onClick={openMobileShareSheet}>
              <i className="bi bi-person-add Mr-1"></i>
              {inviteFriendsButtonText}
            </button>
          )}
      </div>
      {renderDeletePlanConfirmation()}
    </div>
  )
}
