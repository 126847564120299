import type { JSX } from 'react'
import type { CalendarVariantValue } from './constants'

import { CalendarEvent } from 'calendar-link'

import { getCalendarLinkProps } from './utils'

export interface CalendarLinkProps {
  type: CalendarVariantValue
  event: CalendarEvent
}

// todo: why we need to remove rel="noopener noreferrer" for apple calendar links
export function CalendarLink({ type, event }: CalendarLinkProps): JSX.Element {
  const { href, logo, alt } = getCalendarLinkProps(type, event)

  return (
    <a className="EnhancedConfirmation__calendars__item" href={href} target="_blank">
      <img src={logo} alt={alt} />
      <span>{type}</span>
    </a>
  )
}
