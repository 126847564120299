import { Poll as PollType } from '../../../../__generated__/graphql'
import './Poll.scss'
import { ActivePollOption } from '../ActivePollOption/ActivePollOption'
import { ResultsPollOption } from '../ResultsPollOption/ResultsPollOption'

export const Poll = ({ poll }: { poll: PollType }) => {
  const isPollActive = poll.isActive

  const totalVotes = poll.options.map((option) => option.votes.map((vote) => vote))
  const totalVotesLength = totalVotes.map((vote) => vote.length)
  const totalVotesSum = totalVotesLength.reduce((a, b) => a + b, 0)

  return (
    <div className="Poll">
      <div className="Poll__header">
        <div>
          <img
            className="Poll__header__user-picture"
            src={poll.creatorUser.imageUrl || ''}
            alt={poll.creatorUser.firstName || 'User Picture'}
          />
        </div>
        <div className="Poll__header__text">
          <p className="Caption">
            {poll?.creatorUser?.firstName} {isPollActive ? 'is asking' : 'asked'}
          </p>
          <h5>{poll.title}</h5>
        </div>
      </div>
      {isPollActive && (
        <ul className="Poll__options">
          {poll.options.map((option, i) => (
            <ActivePollOption key={i} option={option} />
          ))}
        </ul>
      )}
      {!isPollActive && (
        <ul className="Poll__options">
          <p className="Caption">Results</p>
          {poll.options.map((option, i) => (
            <ResultsPollOption key={i} option={option} totalVotesSum={totalVotesSum} />
          ))}
        </ul>
      )}
    </div>
  )
}
