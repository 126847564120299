export const feedJoinedPathname = '/feed/joined'
export const feedInvitedPathname = '/feed'
export const userProfilePathname = '/user/:userId'
export const signUpOrLogInPathname = '/login'
export const planCreateOrEditPathname = '/plans/edit'
export const invitedPlanPrefix = '/plans/invited'
export function getPathForPlan(planId: string | undefined, fromInvitedFeed?: boolean) {
  if (!planId) return ''
  if (fromInvitedFeed) return `/plans/invited/${planId}`
  return `/plans/${planId}`
}
export function getPathForUser(userId: string) {
  return `/user/${userId}`
}
