import './Loader.scss'

const Loader = ({ size }: { size?: number }) => {
  const sizeRem = size ? `${size}rem` : null
  return (
    <div className="loader-container">
      <div className="loader" style={{ height: sizeRem ?? '30px', width: sizeRem ?? '30px' }}></div>
    </div>
  )
}

export default Loader
