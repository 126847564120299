import { User } from '../../__generated__/graphql'
import { useSelector } from 'react-redux'
import { getAuth } from '@firebase/auth'
import { useEffect, useState } from 'react'
import { store } from '../../store/store'
import PersonCircle from '../../assets/svg/PersonCircle.svg'

import './ProfileMenu.scss'
import { useNavigate } from 'react-router-dom'
import { imageSizer } from '../../util/image-sizer'
import { feedInvitedPathname, feedJoinedPathname, getPathForUser } from '../../constants/path-names'

export const ProfileMenu = ({ unreadCount }: { unreadCount: number }) => {
  const [displayMenu, setDisplayMenu] = useState(false)
  const navigate = useNavigate()

  const handleProfileMenuVisibility = () => {
    setDisplayMenu(!displayMenu)
  }

  const handleMouseLeave = () => {
    setDisplayMenu(false)
  }

  const user = useSelector((state: any) => state.user as User)
  const [userImageSrc, setUserImageSrc] = useState(user?.imageUrl ? user.imageUrl : PersonCircle)
  useEffect(() => {
    if (user?.imageUrl) {
      setUserImageSrc(imageSizer({ url: user.imageUrl, width: 300 }))
    }
  }, [user])

  if (!user?.id) return <></>

  const handleLogout = () => {
    getAuth().signOut()
    store.dispatch({ type: 'user/logout' })
    setDisplayMenu(false)
    navigate(feedJoinedPathname)
    location.reload()
  }

  const navigateToPlans = () => {
    navigate(feedJoinedPathname)
    setDisplayMenu(false)
  }

  const navigateToInvites = () => {
    navigate(feedInvitedPathname)
    setDisplayMenu(false)
  }

  const navigateToProfile = () => {
    navigate(getPathForUser(user.id))
    setDisplayMenu(false)
  }

  const handleMessages = () => {
    navigate('/messages')
    setDisplayMenu(false)
  }

  return (
    <div className="ProfileMenu">
      {user && (
        <div className="ProfileMenu__button" onClick={handleProfileMenuVisibility}>
          <img
            onError={() => setUserImageSrc(PersonCircle)}
            className="ProfileMenu__button__picture"
            src={userImageSrc}
            alt={user.fullName || 'Profile Picture'}
          />
          <div
            className="ProfileMenu__button__badge"
            style={unreadCount && unreadCount > 0 ? {} : { visibility: 'hidden' }}
          ></div>
          <i className="ProfileMenu__button__icon bi bi-chevron-down"></i>
        </div>
      )}
      {displayMenu && (
        <ul className="ProfileMenu__menu" onMouseLeave={handleMouseLeave}>
          <li onClick={navigateToInvites}>Invites</li>
          <li onClick={navigateToPlans}>Plans</li>
          <li className="Flex Flex--align-center" onClick={handleMessages}>
            Messages
            {unreadCount > 0 && (
              <div className="ProfileMenu__menu__badge Flex Flex--align-center Flex--centered">
                <div>{unreadCount}</div>
              </div>
            )}
          </li>
          <li onClick={navigateToProfile}>Profile</li>
          <li onClick={handleLogout}>Log Out</li>
        </ul>
      )}
    </div>
  )
}
