import { UpsertUser } from '../../common-components/UpsertUser/UpsertUser'
import { InitializeFirebaseAuth } from '../../common-components/InitializeFirebaseAuth/InitializeFirebaseAuth'
import { InitializeWebPushNotifications } from '../../common-components/InitializeWebPushNotifications/InitializeWebPushNotifications'
import { InitializeStreamChat } from '../../common-components/InitializeStreamChat/InitializeStreamChat'
import { PageType } from '../../types/page-type.enum'
import { PlanListPage } from '../PlanListPage/PlanListPage'
import { PlanDetailPage } from '../PlanDetailPage/PlanDetailPage'
import { ErrorBoundary } from 'react-error-boundary'
import Error from '../Error/Error'
import Header from '../Header/Header'
import './Base.scss'
import { useSelector } from 'react-redux'
import { ProfilePage } from '../ProfilePage/ProfilePage'
import { SignUpOrLogInPage } from '../SignUpOrLogInPage/SignUpOrLogInPage'
import { ChatList } from '../ChatList/ChatList'
import { PlanCreatePage } from '../PlanCreatePage/PlanCreatePage'

export const Base = ({ pageType }: { pageType: PageType }) => {
  const fallbackRender = ({ error }: { error: Error }) => {
    console.error('Unexpected error caught by ErrorBoundary', { error })
    return (
      <>
        <Header />
        <Error />
      </>
    )
  }

  const authTokenLoaded = useSelector((state: any) => state.authTokenLoaded as boolean)

  return (
    <div className="Base">
      <ErrorBoundary key={location.pathname} fallbackRender={fallbackRender}>
        <InitializeFirebaseAuth />
        <InitializeWebPushNotifications />
        <UpsertUser />
        <InitializeStreamChat />
        {authTokenLoaded && pageType === PageType.signUpOrLogIn && <SignUpOrLogInPage />}
        {authTokenLoaded && pageType === PageType.planListPage && <PlanListPage />}
        {authTokenLoaded && pageType === PageType.planDetailPage && <PlanDetailPage />}
        {authTokenLoaded && pageType === PageType.userProfile && <ProfilePage />}
        {authTokenLoaded && pageType === PageType.messages && <ChatList />}
        {authTokenLoaded && pageType === PageType.planCreatePage && <PlanCreatePage />}
      </ErrorBoundary>
    </div>
  )
}
