import { gql } from '@apollo/client'

// Used for joining a plan
export const planUsersAdd = gql`
  mutation planUsersAdd($planId: String!, $rsvp: RsvpType, $inviterPlanUserId: String) {
    planUsers {
      add(input: { planId: $planId, rsvp: $rsvp, inviterPlanUserId: $inviterPlanUserId })
    }
  }
`
