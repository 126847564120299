import { gql } from '@apollo/client'

export const getPlan = gql`
  query getPlan($id: String!) {
    plans {
      plan(id: $id) {
        id
        title
        description
        channelId
        isVisibilityOpen
        guestCanInvite
        isSnowball
        enableRequestToJoin
        ownerUserId
        startTimeOnly
        startDateOnly
        startAt
        timezone
        currentUserRsvp
        maxCapacity
        capacity {
          maxCapacity
          currentCapacity
          atCapacity
        }
        dates {
          text
          voteUserIds
        }
        startTimeText
        endTimeText
        imageUrl
        links {
          url
          description
        }
        place {
          venue
          address
          placeId
          coordinates {
            lat
            lng
          }
        }
        ownerUser {
          firstName
          imageUrl
        }
        channel {
          imageUrl
          dynamicLinkUrl
          dynamicLinkUrlWeb
          type
          startTime
          recentMessages {
            text
            user {
              id
              name
              imageUrl
            }
          }
          pinnedMessages {
            text
            user {
              id
              name
              imageUrl
            }
          }
        }
        friendsInPlan {
          items {
            id
            firstName
            imageUrl
          }
        }
        wasSeen
      }
    }
  }
`
