import { useQuery } from '@apollo/client'
import { SetStateAction, useEffect, useRef, useState } from 'react'
import { placesFind } from '../../../graphql/queries/places-find'
import './EditCity.scss'
import Skeleton from 'react-loading-skeleton'
import { InfoType } from '../Profile/Profile'
import { Input } from '../../Input/Input'
import { useDebounce } from '../../../hooks/useDebounce'
import { Place } from '../../../__generated__/graphql'

export const EditCity = ({
  isEditProfile,
  infoType,
  city,
  setCity,
  setPlace,
  placeholder,
  displayVenue,
}: {
  isEditProfile?: boolean
  infoType: InfoType
  city: string | null
  setCity?: (value: SetStateAction<string | null>) => void
  setPlace?: (value: SetStateAction<Place | null>) => void
  placeholder?: string
  displayVenue?: boolean
}) => {
  const [displayList, setDisplayList] = useState<boolean>(false)
  const useDebounceCity = useDebounce(city, 500)
  const editCityRef = useRef<HTMLInputElement>(null)

  const handleDocumentClick = (e: any) => {
    if (editCityRef.current && !editCityRef.current.contains(e.target)) {
      setDisplayList(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [displayList])

  const { data, loading } = useQuery(placesFind, {
    variables: { searchTerm: useDebounceCity },
  })
  const places = data?.places?.find || []

  const handleClickAddress = (place: Place) => {
    setCity && setCity(displayVenue ? `${place.venue}, ${place.address}` : place.address)
    setPlace && setPlace(place)
    setDisplayList(false)
  }

  return (
    <div className="EditCity" ref={editCityRef}>
      {isEditProfile && (
        <>
          <h3 className="Mb-1">Location</h3>
          <p className="Mb-6 Caption">We’ll use this to find invites local to you.</p>
        </>
      )}

      <Input
        className="EditCity__input"
        placeHolder={placeholder || 'City'}
        value={city ?? ''}
        onChange={(e) => setCity && setCity(e.target.value)}
        autoFocus={infoType === 'city'}
        onClick={() => setDisplayList(true)}
      />
      {!loading && displayList && places.length !== 0 && (
        <ul className={`EditCity__list ${isEditProfile ? 'Position-abs' : ''}`}>
          {places?.map((place: Place, i: number) => (
            <li className="EditCity__list__item" key={i} onClick={() => handleClickAddress(place)}>
              {displayVenue ? `${place.venue}, ${place.address}` : place.address}
            </li>
          ))}
        </ul>
      )}
      {loading && displayList && (
        <ul className="EditCity__list EditCity__loading">
          <li className="EditCity__loading__item">
            <Skeleton height="40px" />
          </li>
          <li className="EditCity__loading__item">
            <Skeleton height="40px" />
          </li>
          <li className="EditCity__loading__item">
            <Skeleton height="40px" />
          </li>
          <li className="EditCity__loading__item">
            <Skeleton height="40px" />
          </li>
          <li className="EditCity__loading__item">
            <Skeleton height="40px" />
          </li>
        </ul>
      )}
    </div>
  )
}
