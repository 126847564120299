import { isIosDevice } from '../../util/device-info'
import { appStoreUrl } from '../../constants/constants'

// WARN: target=_blank will break dynamic linking to App Store
export const AppLink = (props: any) => {
  const link = props.dynamicLinkUrl ?? process.env.REACT_APP_HOME_DYNAMIC_LINK

  return (
    <a className={props.className} href={(isIosDevice() && link) || appStoreUrl}>
      {props.children}
    </a>
  )
}
