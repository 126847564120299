import { gql } from '@apollo/client'

// Used by host to accept user's request to join
export const planUsersRequestToJoinAccept = gql`
  mutation planUsersRequestToJoinAccept($planId: String!, $userId: String!) {
    planUsers {
      requestToJoinAccept(input: { planId: $planId, userId: $userId })
    }
  }
`
