import { Plan } from '../../../__generated__/graphql'
import './PlanInviteOnlyBanner.scss'

export function PlanInviteOnlyBanner({ plan }: { plan: Plan }): JSX.Element | null {
  if (plan.guestCanInvite) return null
  return (
    <div className="PlanInviteOnlyBanner Flex Flex--col Flex--align-center My-6">
      <i className="PlanInviteOnlyBanner__icon bi bi-info-circle Mb-3" />
      <h3>This Pie Plan is invite-only</h3>
      <p className="Gray-text">Only people invited by the host can RSVP.</p>
    </div>
  )
}
