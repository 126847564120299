import { gql } from '@apollo/client'

export const pollsFind = gql`
  query pollsFind($planId: String!) {
    polls {
      find(input: { planId: $planId }) {
        id
        title
        creatorUser {
          firstName
          imageUrl
        }
        isActive
        options {
          id
          value
          position
          votes {
            userId
          }
          isWinner
        }
      }
    }
  }
`
