import './ConfirmationButtons.scss'

export const ConfirmationButtons = ({
  saveButtonText,
  isDisabled,
  onCancelClick,
  onContinueClick,
}: {
  saveButtonText: string
  isDisabled?: boolean
  onCancelClick: () => void
  onContinueClick: () => void
}) => {
  return (
    <div className="ConfirmationButtons">
      <div className="Flex Pb-3 Flex--space-between ConfirmationButtons__container">
        <button
          className="Button-medium Button-secondary ConfirmationButtons__container__button"
          onClick={onCancelClick}
        >
          Cancel
        </button>
        <button
          disabled={isDisabled}
          className={`Button-medium Button-primary ConfirmationButtons__container__button ConfirmationButtons__container__button--primary ${
            isDisabled ? 'Button-primary--disabled' : ''
          }`}
          onClick={onContinueClick}
        >
          {saveButtonText}
        </button>
      </div>
    </div>
  )
}
