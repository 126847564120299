export function TermsAndService() {
  return (
    <p className="EnhancedAuth__form__terms">
      By pressing accept and continue you agree to Pie's{' '}
      <a className="EnhancedAuth__form__terms__link" href="https://www.getpie.app/terms-of-service" target="_blank">
        terms of service
      </a>
      {' and '}
      <a className="EnhancedAuth__form__terms__link" href="https://www.getpie.app/privacy-policy" target="_blank">
        privacy policy
      </a>
      , and consent to receive text messages from Pie. Message and data rates may apply.
    </p>
  )
}
