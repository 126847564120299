import { gql } from '@apollo/client'

export const usersUpdateMe = gql`
  mutation usersUpdateMe(
    $firstName: String
    $lastName: String
    $tosAgreedVersion: Int
    $bio: String
    $instagram: String
    $twitter: String
    $linkedIn: String
    $city: String
  ) {
    users {
      updateMe(
        input: {
          firstName: $firstName
          lastName: $lastName
          tosAgreedVersion: $tosAgreedVersion
          bio: $bio
          instagram: $instagram
          twitter: $twitter
          linkedIn: $linkedIn
          city: $city
        }
      ) {
        id
        fullName
        imageUrl
        firstName
        lastName
        phoneNumber
        tosAgreedVersion
        bio
        instagram
        twitter
        linkedIn
        city
        userInterests {
          interest {
            id
            name
          }
        }
        favoriteSpots {
          id
          placeId
          venue
          address
          coordinates {
            lat
            lng
          }
          category
          generalLocation
          emoji
        }
      }
    }
  }
`
