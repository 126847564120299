import { useRef } from 'react'
import { useDisablePageScroll } from '../../../hooks/useDisablePageScroll'
import './EditModal.scss'

export const EditModal = ({
  handleDiscardChanges,
  handleSaveInfo,
  onClose,
}: {
  handleDiscardChanges: () => void
  handleSaveInfo: () => void
  onClose: () => void
}) => {
  const modalRef = useRef<HTMLDivElement>(null)

  useDisablePageScroll()

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event.target === modalRef.current) {
      onClose()
    }
  }
  return (
    <div className="EditModal" role="dialog" ref={modalRef} onClick={handleOverlayClick}>
      <div className="EditModal__dialog" role="document">
        <div className="EditModal__dialog__content">
          <div className="EditModal__dialog__content__body">
            <h2 className="Mb-3">Unsaved changes</h2>
            <p className="Mb-6">You have unsaved changes. Do you want to discard them?</p>
            <div className="Flex Flex--centered">
              <button className="Button-small Mr-1 Button-secondary" onClick={handleDiscardChanges}>
                Discard changes
              </button>
              <button className="Button-small Ml-1 Button-primary" onClick={handleSaveInfo}>
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
