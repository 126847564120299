import './GetAppBottomDrawer.scss'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { BottomSheet, store } from '../../store/store'
import { useSelector } from 'react-redux'

function closeBottomSheet() {
  store.dispatch({ type: 'bottomSheet/close' })
}

export const GetAppBottomDrawer = () => {
  const bottomSheetOpen = useSelector((state: any) => {
    return state.bottomSheet === BottomSheet.getApp
  })

  return (
    <Offcanvas
      className="GetAppBottomDrawer"
      placement="bottom"
      scroll={true}
      backdrop={true}
      show={bottomSheetOpen}
      onHide={closeBottomSheet}
    >
      <Offcanvas.Body className="GetAppBottomDrawer__body">
        <h3 className="GetAppBottomDrawer__body__description">Create polls, view profiles, and more on the app</h3>
        <a className="GetAppBottomDrawer__body__app-link">Open the Pie app</a>
        <button onClick={closeBottomSheet} className="GetAppBottomDrawer__body__not-now-button">
          Not now
        </button>
      </Offcanvas.Body>
    </Offcanvas>
  )
}
