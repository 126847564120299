import { store } from '../../store/store'
import { useEffect } from 'react'
import { browserLocalPersistence, getAuth } from '@firebase/auth'
import { FirebaseOptions, initializeApp } from '@firebase/app'

// TODO: Add a retry mechanism for initializers
export function InitializeFirebaseAuth(): null {
  useEffect(() => {
    const initFirebase = async () => {
      const firebaseConfig: FirebaseOptions = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      }
      initializeApp(firebaseConfig)
      await getAuth().setPersistence(browserLocalPersistence)
      const authToken = await getAuth().currentUser?.getIdToken()
      store.dispatch({ type: 'auth/setToken', authToken })
    }
    initFirebase().catch((err) => {
      console.error('Error InitializeFirebaseAuth', err)
    })
  })
  return null
}
