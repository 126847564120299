import { FavoriteSpot } from '../../../__generated__/graphql'
import './FavoriteSpotPill.scss'

export function FavoriteSpotPill({ spot }: { spot: FavoriteSpot }): JSX.Element | null {
  return (
    <div className="FavoriteSpotPill Flex Flex--align-center G-3 Px-5 Py-3">
      <div className="FavoriteSpotPill__icon">{spot.emoji}</div>
      <div>
        <h5>{spot.venue}</h5>
        <p className="Caption">{spot.generalLocation || spot.address}</p>
      </div>
    </div>
  )
}
