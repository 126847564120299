import './Input.scss'

export const Input = ({
  className,
  placeHolder,
  pattern,
  error,
  errorText,
  value,
  autoFocus,
  inputType,
  minDate,
  onChange,
  onClick,
}: {
  className?: string
  placeHolder?: string
  pattern?: string
  error?: boolean
  errorText?: string
  value: string
  autoFocus?: boolean
  inputType?: string
  minDate?: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onClick?: React.MouseEventHandler<HTMLInputElement>
}) => {
  return (
    <div className={`Flex Flex--align-center Fill-width ${className || ''}`}>
      <div className="Fill-width Input">
        <input
          type={inputType ? inputType : 'text'}
          pattern={pattern}
          className={`${placeHolder ? 'Input__input Input__input--with-placeholder' : 'Input__input'}`}
          placeholder={placeHolder}
          value={value}
          onChange={onChange}
          autoFocus={autoFocus}
          onClick={onClick}
          required={error}
          min={minDate}
        />
        <label className="Input__label">{placeHolder}</label>
        {error && <div className="Input__error">{errorText}</div>}
      </div>
    </div>
  )
}
