import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { MarkInvitedInput } from '../__generated__/graphql'
import { planUsersMarkInvited } from '../graphql/mutations/plan-users-mark-invited'
import { useSelector } from 'react-redux'

export const useMarkInvited = ({ planId }: { planId: string }) => {
  const userId = useSelector((state: any) => state.user?.id as string)
  const [markInvitedMutation] = useMutation<{ planUsersMarkInvited: MarkInvitedInput }>(planUsersMarkInvited, {
    variables: { planId },
    refetchQueries: [],
  })

  useEffect(() => {
    if (userId) {
      markInvitedMutation().catch((err) => {
        console.error(`Error marking user as invited for plan ${planId}`, { planId, err })
      })
    }
  }, [userId])
}
