import { PlanDate as PlanDateType } from '../../../../__generated__/graphql'
import { getDatePart } from '../../../../util/get-date-part'
import './PlanDate.scss'

export const PlanDate = ({ dates, timeText }: { dates: PlanDateType[]; timeText: string | null }) => {
  const invalidTimeText = timeText === 'Invalid Date' || timeText === null
  return (
    <div className="PlanDate">
      <i className="bi bi-calendar PlanDate__icon"></i>
      <div className="PlanDate__container">
        {dates?.map((date: PlanDateType, i: number) => (
          <h5 key={i}>
            {getDatePart(date.text, 'dddd')}, {getDatePart(date.text, 'MMM')} {getDatePart(date.text, 'D')}
          </h5>
        ))}
        {timeText && !invalidTimeText && <div>{timeText}</div>}
      </div>
    </div>
  )
}
