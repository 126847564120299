import { FirebaseErrorCodes } from '../../../../types/firebase-error-codes.enum'

export function logSmsError(error: any, phoneNumber: any | undefined) {
  switch (error.code) {
    case FirebaseErrorCodes.invalidPhoneNumber:
      console.warn(`Issue sending SMS: Invalid phone number - ${error.message}`, {
        error,
        phoneNumber,
      })
      break
    case FirebaseErrorCodes.networkRequestFailed:
      console.error(`Issue sending SMS: Network request failed - ${error.message}`, {
        error,
        phoneNumber,
      })
      break
    case FirebaseErrorCodes.tooManyRequests:
      console.warn(`Issue sending SMS: Too many requests - ${error.message}`, {
        error,
        phoneNumber,
      })
      break
    case FirebaseErrorCodes.error39:
      console.error(`Issue sending SMS: Error code 39 - ${error.message}`, {
        error,
        phoneNumber,
      })
      break
    case FirebaseErrorCodes.captchaCheckFailed:
      console.error(`Issue sending SMS: Captcha check failed - ${error.message}`, {
        error,
        phoneNumber,
      })
      break
    case FirebaseErrorCodes.webPushDenied:
      console.error(`Issue sending SMS: Web push permission denied - ${error.message}`, {
        error,
        phoneNumber,
      })
      break
    case FirebaseErrorCodes.internalError:
      console.error(`Issue sending SMS: Internal error - ${error.message}`, {
        error,
        phoneNumber,
      })
      break
    case FirebaseErrorCodes.operationNotAllowed:
      console.error(`Issue sending SMS: Operation not allowed - ${error.message}`, {
        error,
        phoneNumber,
      })
      break
    case FirebaseErrorCodes.invalidAppCredential:
      console.error(`Issue sending SMS: Invalid app credential - ${error.message}`, {
        error,
        phoneNumber,
      })
      break
    default:
      console.error(`Issue sending SMS: Unknown error - ${error.message}`, {
        error,
        phoneNumber,
      })
  }
}
