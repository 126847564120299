import { gql } from '@apollo/client'

export const interestsList = gql`
  query interestsFind {
    interests {
      find {
        id
        name
      }
    }
  }
`
