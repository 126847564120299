import { useSelector } from 'react-redux'
import './ButtonsCarousel.scss'

export const ButtonsCarousel = ({
  isDisplayingDate,
  isDisplayingTime,
  isDisplayingLocation,
  isDisplayingInfo,
  isDisplayingLinks,
  toggleDate,
  toggleTime,
  toggleLocation,
  toggleInfo,
  toggleLink,
}: {
  isDisplayingDate: boolean
  isDisplayingTime: boolean
  isDisplayingLocation: boolean
  isDisplayingInfo: boolean
  isDisplayingLinks: boolean
  toggleDate: () => void
  toggleTime: () => void
  toggleLocation: () => void
  toggleInfo: () => void
  toggleLink: any
}) => {
  const isMobile = useSelector((state: any) => {
    return state.isMobile
  })

  const isButtonsCarouselDisplay =
    !isDisplayingDate || !isDisplayingTime || !isDisplayingLocation || !isDisplayingInfo || !isDisplayingLinks

  if (!isButtonsCarouselDisplay) {
    return null
  }

  return (
    <div className="Flex Flex--align-center G-3 ButtonsCarousel">
      {!isMobile && <h5>Add Details </h5>}
      {isMobile && <h5>Add</h5>}
      {!isDisplayingDate && (
        <button className="Button-medium Button-secondary" type="button" onClick={toggleDate}>
          <div className="Flex">
            <i className="bi bi-calendar Mr-1" />
            Date
          </div>
        </button>
      )}
      {!isDisplayingTime && (
        <button className="Button-medium Button-secondary" type="button" onClick={toggleTime}>
          <div className="Flex">
            <i className="bi bi-clock Mr-1" />
            Time
          </div>
        </button>
      )}
      {!isDisplayingLocation && (
        <button className="Button-medium Button-secondary" type="button" onClick={toggleLocation}>
          <div className="Flex">
            <i className="bi bi-geo-alt Mr-1" />
            Location
          </div>
        </button>
      )}
      {!isDisplayingInfo && (
        <button className="Button-medium Button-secondary" type="button" onClick={toggleInfo}>
          <div className="Flex">
            <i className="bi bi-info-circle Mr-1" />
            Info
          </div>
        </button>
      )}
      {!isDisplayingLinks && (
        <button className="Button-medium Button-secondary" type="button" onClick={toggleLink}>
          <div className="Flex">
            <i className="bi bi-link-45deg Mr-1" />
            Link
          </div>
        </button>
      )}
    </div>
  )
}
