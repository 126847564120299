import { dayjs } from './dayjs-util'

/**
 * If timezone is different than user's timezone,
 *  return timezone abbreviation i.e. 'PDT'.
 * @param timezone identifier e.g. 'America/Los_Angeles'
 */
export const getTimezoneText = (timezone: string) => {
  const userTimezone = dayjs.tz.guess()

  if (userTimezone !== timezone) {
    return dayjs().tz(timezone).format('z')
  }
  return null
}
