import { datadogRum } from '@datadog/browser-rum'

export function initDdRum() {
  if (process.env.REACT_APP_DD_APPLICATION_ID) {
    datadogRum.init({
      proxy: `${process.env.REACT_APP_API_HOST}/dd/rum`,
      applicationId: process.env.REACT_APP_DD_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN || '',
      site: process.env.REACT_APP_DD_SITE,
      service: process.env.REACT_APP_DD_SERVICE,
      env: process.env.REACT_APP_DD_ENV,
      version: '1.0.0',
      sessionSampleRate: 100,
      // disable session replay
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    })
    datadogRum.startSessionReplayRecording()
  }
}
