import './AddInfo.scss'

export const AddInfo = ({ icon, text, onAddInfoClick }: { icon: string; text: string; onAddInfoClick: () => void }) => {
  return (
    <button className="AddInfo Button-secondary Button-small Flex" onClick={onAddInfoClick}>
      <i className={`AddInfo__icon bi ${icon}`}></i>
      <p className="AddInfo__text Mt-2">{text}</p>
    </button>
  )
}
