import { gql } from '@apollo/client'

export const usersSignUp = gql`
  mutation usersSignUp {
    users {
      signUp(input: { device: web }) {
        id
        bio
        fullName
        imageUrl
        firstName
        lastName
        phoneNumber
        city
        instagram
        twitter
        linkedIn
        tosAgreedVersion
        planUsers {
          items {
            id
            userId
            rsvp
          }
        }
        favoriteSpots {
          id
          placeId
          venue
          address
          coordinates {
            lat
            lng
          }
          category
          generalLocation
          emoji
        }
      }
    }
  }
`
