import './ChannelDetail.scss'

export const ChannelDetail = ({ planDescription }: { planDescription: string }) => {
  const renderFormattedDescription = (text: string) => {
    return text.split('\n').map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ))
  }

  return (
    <div className="ChannelDetail">
      <p className="ChannelDetail__container">{renderFormattedDescription(planDescription)}</p>
    </div>
  )
}
