import './Error.scss'
import ErrorFace from '../../assets/svg/ErrorFace.svg'

const Error = () => {
  return (
    <div>
      <div className="Error-container">
        <img className="Error-face-image" src={ErrorFace} alt="Error Face" />
        <h1 className="Error-title">Uh oh</h1>
        <div className="Error-description">
          We can’t find what you’re looking for. You can get back to making plans or{' '}
          <a className="Error-contact-email-anchor" href="mailto:hello@pumpkinpie.com">
            contact us.
          </a>
        </div>
      </div>
    </div>
  )
}
export default Error
