import type { JSX } from 'react'

import { FormEvent, useEffect, useState } from 'react'
import { AuthErrorCodes, RecaptchaVerifier, getAuth, signInWithPhoneNumber } from '@firebase/auth'
import ReactInputVerificationCode from 'react-input-verification-code'

import Loader from '../../Loader/Loader'
import { useMutation } from '@apollo/client'
import { UsersMutations } from '../../../__generated__/graphql'
import { usersSignUp } from '../../../graphql/mutations/users-sign-up'
import { store } from '../../../store/store'
import { AuthStepName } from '../../../types/auth-step-name.enum'
import { createMixpanelEvent } from '../../../util/create-mixpanel-event'
import { MixpanelEvent } from '../../../types/mixpanel-event.enum'
import { useLocation } from 'react-router-dom'

type TAuthErrorCodes = typeof AuthErrorCodes
type TInvalidAuthCodeKey = keyof TAuthErrorCodes
type TInvalidAuthCodeValue = TAuthErrorCodes[TInvalidAuthCodeKey]

export interface EnhancedAuthVerificationCodeProps {
  setAuthStep: (step: AuthStepName) => void
  phoneNumberProp: string
}

export function EnhancedAuthVerificationCode({
  setAuthStep,
  phoneNumberProp,
}: EnhancedAuthVerificationCodeProps): JSX.Element {
  const [verificationCode, setVerificationCode] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isResendDisabled, setIsResendDisabled] = useState(false)
  const [resendCooldown, setResendCooldown] = useState(0)
  const [error, setError] = useState<TInvalidAuthCodeValue | null>(null)

  const location = useLocation()

  useEffect(() => {
    if (resendCooldown > 0) {
      const timer = setInterval(() => {
        setResendCooldown((prevCooldown) => prevCooldown - 1)
      }, 1000)

      return () => {
        clearInterval(timer)
      }
    } else {
      return setIsResendDisabled(false)
    }
  }, [resendCooldown])

  useEffect(() => {
    const auth = getAuth()
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible',
        callback: () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
        'expired-callback': () => {
          // Response expired. Ask user to solve reCAPTCHA again.
        },
      })
    }
  }, [])

  const handleNumberInputsChange = (values: any) => {
    setVerificationCode(values)
  }

  const [signUpMutation] = useMutation<{ users: UsersMutations }>(usersSignUp)

  const handleVerifyCode = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    createMixpanelEvent(MixpanelEvent.confirmVerificationCodeClicked, {
      pathname: location.pathname,
      platform: 'web',
    })

    setError(null)
    setIsLoading(true)
    window.confirmationResult
      .confirm(verificationCode)
      .then(async () => {
        const response = await signUpMutation()
        if (response && response.data?.users.signUp) {
          const user = response.data.users.signUp
          const authToken = await getAuth().currentUser?.getIdToken()

          store.dispatch({ type: 'auth/setToken', authToken })
          store.dispatch({ type: 'user/upsert', user })

          if (!user.fullName) {
            setAuthStep(AuthStepName.name)
          } else if (!user.imageUrl) {
            setAuthStep(AuthStepName.picture)
          }
        }
        setIsLoading(false)
      })
      .catch((error: any) => {
        console.warn('Issue confirming verification code: ', error)
        if (error.code) {
          setError(error.code)
        } else {
          setError(error)
        }
        setIsLoading(false)
      })
  }

  const handleChangePhoneNumber = () => {
    setAuthStep(AuthStepName.phoneNumber)
  }

  const handleResendCode = () => {
    if (isResendDisabled) return
    setError(null)
    setIsResendDisabled(true)
    setResendCooldown(10)
    setIsLoading(true)

    const appVerifier = window.recaptchaVerifier
    const auth = getAuth()
    appVerifier.verify().then(() => {
      signInWithPhoneNumber(auth, String(phoneNumberProp), appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult
          setIsLoading(false)
        })
        .catch(setError)
    })
  }

  return (
    <article className="EnhancedAuth">
      <header className="EnhancedAuth__header">
        <h1>enter the code we sent to {phoneNumberProp}</h1>
      </header>
      <div className="EnhancedAuth__main">
        <form className="EnhancedAuth__form" onSubmit={handleVerifyCode}>
          <div className="EnhancedAuth__form__input-wrapper">
            <ReactInputVerificationCode placeholder="" autoFocus length={6} onChange={handleNumberInputsChange} />
          </div>
          {error != null && (
            <p className="EnhancedAuth__form__error">
              {error === AuthErrorCodes.INVALID_CODE ? 'wrong code, please try again.' : 'something went wrong.'}
            </p>
          )}
          <footer className="EnhancedAuth__footer">
            <div className="EnhancedAuth__form__secondary-btns-wrapper">
              <button
                className={`Button-medium Button-secondary${isResendDisabled ? '--disabled' : ''} Fill-width`}
                onClick={handleResendCode}
              >
                resend code
              </button>
              <button className="Button-secondary Button-medium Fill-width" onClick={handleChangePhoneNumber}>
                change number
              </button>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <button className="EnhancedAuth__form__verify-btn EnhancedButton-primary Button-large">
                accept & continue
              </button>
            )}
          </footer>
        </form>
      </div>
    </article>
  )
}
