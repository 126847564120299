import { useNavigate } from 'react-router-dom'
import { ChannelNavButtons } from '../../ChannelNavButtons/ChannelNavButtons'
import './AnonymUser.scss'
import { feedInvitedPathname, signUpOrLogInPathname, getPathForUser } from '../../../constants/path-names'
import { useQuery } from '@apollo/client'
import Loader from '../../Loader/Loader'
import Error from '../../Error/Error'
import { limitedUser } from '../../../graphql/queries/get-limited-user'
import { LimitedUser } from '../../../__generated__/graphql'
import { AuthType } from '../../../util/auth-type.enum'

export const AnonymUser = ({ userId }: { userId: string }) => {
  const navigate = useNavigate()
  const handleLogIn = () => {
    navigate(signUpOrLogInPathname, {
      state: {
        redirectTo: getPathForUser(userId) || feedInvitedPathname,
        authType: AuthType.signIn,
      },
    })
  }

  const { data, loading, error } = useQuery<{ limitedUser: LimitedUser }>(limitedUser, {
    variables: {
      userId: userId,
    },
  })

  if (loading) return <Loader />

  if (!data || error) {
    return <Error />
  }

  return (
    <div className="AnonymUser Card">
      <div className="Flex Flex--col Flex--align-stretch">
        <ChannelNavButtons isBackButton />
        <div className="AnonymUser__container">
          <div className="Flex Flex--space-between Flex--align-end AnonymUser__container__user-detail">
            <h1>{data.limitedUser.firstName}</h1>
            <img className="AnonymUser__container__user-detail__picture" src={data.limitedUser.imageUrl || ''} />
          </div>
          <hr />
          <div className="Flex Flex--col Flex--align-center">
            <p className="AnonymUser__container__subtitle">
              Join Pie to make your own plans, view full profiles, and more!
            </p>
            <button className="Button-primary Button-large Mt-6 AnonymUser__container__button" onClick={handleLogIn}>
              Join Pie
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
