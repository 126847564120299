import { gql } from '@apollo/client'

export const placesFind = gql`
  query placesFind($searchTerm: String!) {
    places {
      find(searchTerm: $searchTerm) {
        venue
        address
        placeId
        coordinates {
          lat
          lng
        }
        generalLocation
      }
    }
  }
`
