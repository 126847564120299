import { gql } from '@apollo/client'

export const deviceTokensUpsert = gql`
  mutation deviceTokensUpsert($token: String!) {
    deviceTokens {
      upsert(input: { token: $token, platform: web }) {
        userId
        token
        platform
        expiredAt
      }
    }
  }
`
