import './InterestsPill.scss'
import { Interest } from './InterestsPills'

export const InterestsPill = ({
  interest,
  isDisabled,
  onInterestToggle,
}: {
  interest: Interest
  isDisabled?: boolean
  onInterestToggle: (id: string) => void
}) => {
  const insterestNameWithoutEmoji = interest.name.split(' ').slice(1).join(' ')

  return (
    <button
      type="button"
      disabled={isDisabled}
      key={interest.id}
      className={`InterestsPill ${interest.didSelect ? 'InterestsPill--selected' : ''} ${
        isDisabled ? 'InterestsPill--disabled' : ''
      }`}
      onClick={() => onInterestToggle(interest.id)}
    >
      {interest.didSelect && !isDisabled && (
        <div className="Flex">
          <i className="bi bi-check InterestsPill__checkmark"></i> <p>{insterestNameWithoutEmoji}</p>
        </div>
      )}
      {(!interest.didSelect || isDisabled) && <p>{interest.name}</p>}
    </button>
  )
}
