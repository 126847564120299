import { Channel, StreamChat } from 'stream-chat'
import { Chat as ChatStream, Channel as ChannelComponent, MessageInput, MessageList, Window } from 'stream-chat-react'
import 'stream-chat-react/dist/css/v2/index.css'
import { Plan } from '../../__generated__/graphql'
import { useEffect, useRef } from 'react'
import './Chat.scss'
import Loader from '../Loader/Loader'
import CustomSystemMessage from './CustomSystemMessage'
import BackIcon from '../../assets/svg/BackIcon.svg'
import { CustomMessageInput } from './CustomMessageInput'
import { useQuery } from '@apollo/client'
import { planUsersFindPlanMembers } from '../../graphql/queries/plan-users-find-plan-members'
import { useSelector } from 'react-redux'

const Chat = ({
  client,
  streamChannel,
  plan,
  handleChatBackButton,
}: {
  client: StreamChat
  streamChannel: Channel
  plan: Plan
  handleChatBackButton: () => void
}) => {
  const { data: planUsersData } = useQuery(planUsersFindPlanMembers, {
    variables: { planId: plan.id },
  })
  const channelMembersCount = planUsersData?.planUsers?.findPlanMembers.total

  const ref = useRef<HTMLDivElement>(null)
  const isMobile = useSelector((state: any) => {
    return state.isMobile
  })

  useEffect(() => {
    if (!client || !streamChannel) return

    const handleChange = () => {
      scrollToBottom()
    }

    client.on('message.new', handleChange)

    return () => {
      client.off('message.new', handleChange)
    }
  }, [client, streamChannel])

  const scrollToBottom = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' })
  }

  if (isMobile && (!client || !streamChannel))
    return (
      <div className="Mobile-loader">
        <Loader />
      </div>
    )

  if (!client || !streamChannel) return <Loader />

  return (
    <>
      <ChatStream
        customClasses={{
          chatContainer: 'custom-chat-stream-container',
        }}
        client={client}
        theme="str-chat__theme-light"
      >
        <ChannelComponent channel={streamChannel} MessageSystem={CustomSystemMessage}>
          <Window>
            <div className="Chat-window-wrapper">
              <div className="Chat-window-header">
                <img
                  className="Chat-window-header-image"
                  src={BackIcon}
                  alt="Back-icon"
                  onClick={handleChatBackButton}
                />
                <div className="Chat-window-header-text">
                  {plan.title && <div className="Chat-window-header-title">{plan.title}</div>}
                  {channelMembersCount > 1 && (
                    <span className="Chat-window-header-subtitle">{channelMembersCount} People</span>
                  )}
                </div>
              </div>

              <div className="Chat-window-list">
                <MessageList hideDeletedMessages disableQuotedMessages messageActions={[]} />
              </div>

              <div className="message-input">
                <MessageInput Input={CustomMessageInput} grow={true} />
              </div>
              <div ref={ref} />
            </div>
          </Window>
        </ChannelComponent>
      </ChatStream>
    </>
  )
}

export default Chat
