import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const SkeletonLoaderPlanCard = () => {
  return (
    <div>
      <hr />
      <h2 className="Mt-6 Mb-2">
        <Skeleton borderRadius="1.25rem" width="140px" />
      </h2>
      <div>
        <Skeleton count={2} height="130px" borderRadius="1.25rem" />
      </div>
    </div>
  )
}
