import { useEffect, useState } from 'react'
import { Channel as StreamChannel, StreamChat } from 'stream-chat'
import { Plan } from '../__generated__/graphql'

export const useGetStreamChannel = (streamChatClient: StreamChat | null, plan: Plan, userId: string): StreamChannel => {
  const [streamChannel, setStreamChannel] = useState(null as any | null)
  useEffect(() => {
    if (streamChatClient && plan && plan.channel && userId) {
      const streamChannel = streamChatClient.channel(plan.channel.type, plan.id, {
        members: [userId],
      })
      setStreamChannel(streamChannel)
    }
  }, [streamChatClient, plan, userId])
  return streamChannel
}
