export enum FirebaseErrorCodes {
  webPushDenied = 'messaging/permission-blocked',
  invalidPhoneNumber = 'auth/invalid-phone-number',
  networkRequestFailed = 'auth/network-request-failed',
  tooManyRequests = 'auth/too-many-requests',
  error39 = 'auth/error-code:-39',
  captchaCheckFailed = 'auth/captcha-check-failed',
  internalError = 'auth/internal-error',
  operationNotAllowed = 'auth/operation-not-allowed',
  invalidAppCredential = 'auth/invalid-app-credential',
}
