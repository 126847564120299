import { useEffect } from 'react'

export function useDisablePageScroll() {
  useEffect(() => {
    document.body.style.overflowY = 'hidden'
    document.body.style.overflowX = 'hidden'
    return () => {
      document.body.style.overflowY = 'auto'
      document.body.style.overflowX = 'auto'
    }
  }, [])
}
