import Park from '../../../assets/svg/Park.svg'
import './PlanListEmpty.scss'
import { createMixpanelEvent } from '../../../util/create-mixpanel-event'
import { MixpanelEvent } from '../../../types/mixpanel-event.enum'
import { feedJoinedPathname, planCreateOrEditPathname } from '../../../constants/path-names'
import { useNavigate } from 'react-router-dom'
import { enablePlanCreation } from '../../../constants/constants'
import { PreviousView } from '../../../types/previous-view.enum'

export function PlanListEmpty({ text }: { text: string }) {
  const navigate = useNavigate()
  const makePlan = () => {
    createMixpanelEvent(MixpanelEvent.clickMakePlanButton)
    navigate(planCreateOrEditPathname, {
      state: {
        previousView: location.pathname === feedJoinedPathname ? PreviousView.joinedFeed : PreviousView.invitedFeed,
      },
    })
  }

  return (
    <div className="PlanListEmpty Card">
      <div className="Flex Flex--col Flex--align-center G-2">
        <img className="PlanListEmpty__image" src={Park} alt="Error Face" />
        <p className="PlanListEmpty__text">{text}</p>
        {enablePlanCreation && (
          <button className="Button-primary Button-large Mt-3" onClick={makePlan}>
            Make a Plan
          </button>
        )}
      </div>
    </div>
  )
}
