import { ChannelNavButtons } from '../../ChannelNavButtons/ChannelNavButtons'
import Loader from '../../Loader/Loader'
import Error from '../../Error/Error'
import { AddPieFriend } from '../AddPieFriend/AddPieFriend'
import { UserDetails } from '../UserDetails/UserDetails'
import { useQuery } from '@apollo/client'
import { User, UsersQueries } from '../../../__generated__/graphql'
import { user } from '../../../graphql/queries/users-user'
import { useParams, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './Profile.scss'
import { MutualFriends } from '../MutualFriends/MutualFriends'
import { ProfileJoinedPlans } from '../ProfileJoinedPlans/ProfileJoinedPlans'
import { AnonymUser } from '../AnonymUser/AnonymUser'
import { useState } from 'react'
import { EditProfile } from '../EditProfile/EditProfile'
import { InterestsPills } from '../../InterestsPills/InterestsPills'
import { userInterestsQuery } from '../../../graphql/queries/user-users-interests'

export type InfoType = 'city' | 'instagram' | 'twitter' | 'linkedIn' | 'bio' | 'interests' | 'favoriteSpots' | null

export const Profile = () => {
  const me = useSelector((state: any) => state.user as User)
  const [searchParams] = useSearchParams()
  const userId = searchParams.get('id') || useParams().userId
  const [editProfile, setEditProfile] = useState(false)
  const [infoType, setInfoType] = useState<InfoType>(null)

  const { data, loading, error } = useQuery<{ users: UsersQueries }>(user, {
    variables: {
      userId: userId,
    },
  })

  const {
    data: userInterestsData,
    loading: interestsLoading,
    error: interestsError,
  } = useQuery<{ users: UsersQueries }>(userInterestsQuery, {
    variables: {
      userId: userId,
    },
  })

  const handleEditProfile = () => {
    setInfoType(null)
    setEditProfile(true)
  }

  const handleAddInterests = () => {
    setEditProfile(true)
  }

  if (!userId) {
    return <Error />
  }

  if (me.id === undefined) {
    return <AnonymUser userId={userId} />
  }

  const isMyId = me.id === userId

  if (loading || interestsLoading) return <Loader />

  if (!data || error || interestsError) {
    return <Error />
  }

  const userProfile = data.users.user
  const interests = userProfile.userInterests.map((interest) => interest.interest)
  const initialInterestsIds = interests.map((interest) => interest.id)

  const currentUserHasInterests = userInterestsData?.users.user.userInterests.length !== 0

  return (
    <div className="Profile Card">
      {!editProfile && (
        <div className="Profile__content Flex Flex--col Flex--align-stretch">
          <ChannelNavButtons isBackButton isMyId={isMyId} handleEditProfile={handleEditProfile} />
          <div className="Profile__content__container">
            <UserDetails
              userProfile={userProfile}
              isMyId={isMyId}
              me={me}
              setEditProfile={setEditProfile}
              setInfoType={setInfoType}
              interests={interests}
            />
            {!isMyId && (
              <>
                <AddPieFriend userProfile={userProfile} />
                {interests.length > 0 && (
                  <div>
                    <hr className="Profile__content__divider" />
                    <h2 className="Mb-2">Their Interests</h2>
                    <InterestsPills interests={interests} isDisabled />
                    {!currentUserHasInterests && (
                      <div className="Flex Flex--col Flex--align-center Mt-6 Profile__content__add-interests">
                        <p className="Profile__content__add-interests__text">
                          See what interests you have in common with other people on Pie
                        </p>
                        <button className="Button-primary Button-small Mt-2" onClick={handleAddInterests}>
                          Add Your Interests
                        </button>
                      </div>
                    )}
                  </div>
                )}
                <MutualFriends userId={userProfile.id} />
                <ProfileJoinedPlans userProfile={userProfile} />
              </>
            )}
          </div>
        </div>
      )}
      {editProfile && (
        <div className="Flex Flex--col Flex--align-stretch">
          <EditProfile
            infoType={infoType}
            userProfile={me}
            setEditProfile={setEditProfile}
            initialInterestsIds={initialInterestsIds}
          />
        </div>
      )}
    </div>
  )
}
