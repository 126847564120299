import { EventComponentProps } from 'stream-chat-react'
import './CustomSystemMessage.scss'

const CustomSystemMessage = (props: EventComponentProps) => {
  const { message } = props

  const { text, type } = message

  if (type === 'system') {
    return (
      <div className="custom-system-message">
        <span>{text}</span>
      </div>
    )
  }

  return null
}

export default CustomSystemMessage
