import Skeleton from 'react-loading-skeleton'

export const SkeletonLoaderPlanList = () => {
  return (
    <div>
      <h1>
        <Skeleton borderRadius="1.25rem" width="200px" />
      </h1>
      <div className="Mb-6 Mt-2">
        <Skeleton count={1} height="130px" borderRadius="1.25rem" />
      </div>
      <div className="Mb-6">
        <Skeleton count={1} height="130px" borderRadius="1.25rem" />
      </div>
      <div className="Mb-6">
        <Skeleton count={1} height="130px" borderRadius="1.25rem" />
      </div>
      <div>
        <Skeleton count={1} height="130px" borderRadius="1.25rem" />
      </div>
    </div>
  )
}
