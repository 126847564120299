import Header from '../Header/Header'
import { useSelector } from 'react-redux'
import { Profile } from './Profile/Profile'

export const ProfilePage = () => {
  const userLoaded = useSelector((state: any) => state.userLoaded as boolean)

  const isMobile = useSelector((state: any) => {
    return state.isMobile
  })

  if (!userLoaded) {
    return null
  }

  return (
    <>
      {!isMobile && <Header />}
      <Profile />
    </>
  )
}
