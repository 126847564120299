import { useState } from 'react'
import { EnhancedAuthVerificationCode } from './EnhancedAuthVerificationCode/EnhancedAuthVerificationCode'
import { EnhancedAuthPhoneNumber } from './EnhancedAuthPhoneNumber/EnhancedAuthPhoneNumber'
import { EnhancedAuthName } from './EnhancedAuthName/EnhancedAuthName'
import { EnhancedAuthPicture } from './EnhancedAuthPicture/EnhancedAuthPicture'
import { AuthStepName } from '../../types/auth-step-name.enum'
import { Plan } from '../../__generated__/graphql'

export const EnhancedAuth = ({ plan }: { plan: Plan | null }) => {
  const [step, setAuthStep] = useState<AuthStepName>(AuthStepName.phoneNumber)
  const [phoneNumberProp, setPhoneNumberProp] = useState('')

  switch (step) {
    case AuthStepName.phoneNumber:
      return <EnhancedAuthPhoneNumber setAuthStep={setAuthStep} setPhoneNumberProp={setPhoneNumberProp} plan={plan} />
    case AuthStepName.verificationCode:
      return <EnhancedAuthVerificationCode setAuthStep={setAuthStep} phoneNumberProp={phoneNumberProp} />
    case AuthStepName.name:
      return <EnhancedAuthName setAuthStep={setAuthStep} plan={plan} />
    case AuthStepName.picture:
      return <EnhancedAuthPicture plan={plan} />
    default:
      console.error('Unexpected auth step!', { step })
      return null
  }
}
