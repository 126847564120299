import Loader from '../../Loader/Loader'
import './EditPortrait.scss'

export const EditPortrait = ({
  picPreview,
  error,
  isLoading,
  onChange,
}: {
  picPreview: string
  error: any
  isLoading: boolean
  onChange: (e: any) => void
}) => {
  return (
    <div className="Flex Mb-6 EditPortrait">
      <div>
        <h3 className="Mb-2">Portrait</h3>
        <label htmlFor="file-input" className="EditPortrait__file-input-label">
          <input type="file" className="EditPortrait__file-input-label__input" accept="image/*" onChange={onChange} />
          <button className="Button-small Button-secondary">Change photo</button>
        </label>
        {error && <p className="Pink-text">We were unable to upload your picture. Files must be less than 20 MB.</p>}
      </div>
      {isLoading ? <Loader /> : <img className="EditPortrait__picture" src={picPreview} alt="Profile Picture" />}
    </div>
  )
}
