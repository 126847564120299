import './EditFavoriteSpot.scss'
import { CreateFavoriteSpotInput, FavoriteSpotCategory, Place } from '../../../../../__generated__/graphql'
import { Input } from '../../../../Input/Input'
import Skeleton from 'react-loading-skeleton'
import { useQuery } from '@apollo/client'
import { placesFind } from '../../../../../graphql/queries/places-find'
import { useDebounce } from '../../../../../hooks/useDebounce'
import { useState } from 'react'

export const EditFavoriteSpot = ({
  category,
  spot,
  setSpot,
}: {
  category: FavoriteSpotCategory | null
  spot: CreateFavoriteSpotInput | undefined
  setSpot: (category: FavoriteSpotCategory | null, data: Place | null) => void
}) => {
  const spotText = (spot: Place | CreateFavoriteSpotInput | undefined): string => {
    if (!spot) return ''
    if (spot.venue && spot.address) return [spot.venue, spot.address].join(', ')
    if (spot.address) return spot.address
    if (spot.venue) return spot.venue
    return ''
  }

  const [displayList, setDisplayList] = useState<boolean>(false)
  const [searchText, setSearchText] = useState(spotText(spot) || '')

  const selectSearchResult = (place: Place) => {
    setDisplayList(false)
    setSpot(category, place)
    setSearchText(spotText(place))
  }

  const titleForCategory = (category: FavoriteSpotCategory | null): string => {
    const categoryToTitle = {
      [FavoriteSpotCategory.Cafe]: 'Favorite Cafe',
      [FavoriteSpotCategory.Bar]: 'Favorite Bar',
      [FavoriteSpotCategory.Restaurant]: 'Favorite Restaurant',
      [FavoriteSpotCategory.Park]: 'Favorite Park',
    }
    if (category) return categoryToTitle[category]
    return 'Other'
  }

  const { data, loading } = useQuery(placesFind, {
    variables: { searchTerm: useDebounce(searchText, 500) },
  })
  const places = data?.places?.find || []

  const editFavoriteSpot = (e: any) => {
    e.preventDefault()
    setDisplayList(true)
  }

  const removeFavoriteSpot = (e: any) => {
    e.preventDefault()
    setSpot(category, null)
    setSearchText('')
    setDisplayList(false)
  }
  return (
    <div className="EditFavoriteSpot">
      {!spot && (
        <button
          onClick={editFavoriteSpot}
          className="EditFavoriteSpot__add-button Button-secondary Button-large Flex Flex--align-center"
        >
          <i className="EditFavoriteSpot__plus-icon bi bi-plus"></i>
          {titleForCategory(category)}
        </button>
      )}
      {(displayList || spotText(spot)) && (
        <div className="Flex Flex--align-center G-3">
          <Input
            className="EditFavoriteSpot__input"
            placeHolder={titleForCategory(category)}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onClick={() => setDisplayList(true)}
          />
          <button className="EditFavoriteSpot__minus-button Button-secondary Button-large" onClick={removeFavoriteSpot}>
            <i className="EditFavoriteSpot__minus-button__icon bi bi-dash"></i>
          </button>
        </div>
      )}
      {!loading && displayList && places !== undefined && places.length !== 0 && (
        <ul className="EditFavoriteSpot__list">
          {places?.map((place: Place, i: number) => (
            <li className="EditFavoriteSpot__list__item" key={i} onClick={() => selectSearchResult(place)}>
              {[place.venue, place.address].join(', ')}
            </li>
          ))}
        </ul>
      )}
      {loading && displayList && (
        <ul className="EditFavoriteSpot__list EditFavoriteSpot__loading">
          <li className="EditFavoriteSpot__loading__item">
            <Skeleton height="40px" />
          </li>
          <li className="EditFavoriteSpot__loading__item">
            <Skeleton height="40px" />
          </li>
          <li className="EditFavoriteSpot__loading__item">
            <Skeleton height="40px" />
          </li>
          <li className="EditFavoriteSpot__loading__item">
            <Skeleton height="40px" />
          </li>
          <li className="EditFavoriteSpot__loading__item">
            <Skeleton height="40px" />
          </li>
        </ul>
      )}
    </div>
  )
}
