import { Input } from '../../Input/Input'
import { ButtonHide } from '../../ButtonHide/ButtonHide'
import { PlanLinkInput } from '../../../__generated__/graphql'
import './PlanEditLinkInputs.scss'

export const PlanEditLinkInputs = ({
  planLinks,
  setPlanLinks,
}: {
  planLinks: PlanLinkInput[]
  setPlanLinks: React.Dispatch<React.SetStateAction<PlanLinkInput[]>>
}) => {
  const handleChangeUrl = (index: number, value: string) => {
    const newValues = [...planLinks]
    newValues[index] = { url: value, description: newValues[index]?.description || null }
    setPlanLinks(newValues)
  }

  const handleChangeDesc = (index: number, value: string) => {
    const newValues = [...planLinks]
    newValues[index] = { url: newValues[index]?.url, description: value || null }
    setPlanLinks(newValues)
  }

  const addInput = () => {
    if (planLinks.length < 3) {
      setPlanLinks([...planLinks, { url: '', description: '' }])
    }
  }

  const handleHideButtonClick = (index: number) => {
    const newValues = [...planLinks]
    newValues.splice(index, 1)
    setPlanLinks(newValues)
  }

  return (
    <div className="PlanEditLinkInputs">
      <div className="Flex Flex--col Gr-6">
        {planLinks.map((link: PlanLinkInput, index: number) => (
          <div key={index} className="Flex G-3">
            <div className="PlanEditLinkInputs__link-inputs-container Flex Flex--col G-3 Fill-width">
              <Input
                placeHolder="Link"
                value={link.url}
                inputType="url"
                onChange={(e) => handleChangeUrl(index, e.target.value)}
                autoFocus={index === 1 || index === 2}
              />
              <Input
                placeHolder="Custom Text (Optional)"
                value={link.description || ''}
                onChange={(e) => handleChangeDesc(index, e.target.value)}
                autoFocus={index === 1 || index === 2}
              />
            </div>
            <ButtonHide onHideButtonClick={() => handleHideButtonClick(index)} style={{ alignSelf: 'center' }} />
          </div>
        ))}
      </div>
      {planLinks.length < 3 && planLinks.length > 0 && (
        <div className="Flex Flex--align-center">
          <button className="Button-small Button-secondary Mt-3" type="button" onClick={addInput}>
            <i className="bi bi-plus"></i>Add Another Link
          </button>
          <p className="Mt-3 Ml-3 Caption">Add up to 3 links</p>
        </div>
      )}
    </div>
  )
}
