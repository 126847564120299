import { Plan, RsvpType } from '../../../__generated__/graphql'
import './PlanInfoBanner.scss'

export function PlanInfoBanner({ plan }: { plan: Plan }): JSX.Element | null {
  const planAtCapacity = plan.capacity && plan.capacity.atCapacity
  if (!planAtCapacity) return null

  const userIsRsvped = plan.currentUserRsvp && [RsvpType.Yes, RsvpType.Maybe].includes(plan.currentUserRsvp)
  const headerText = userIsRsvped ? 'This Pie Plan is Full' : 'This Pie Plan is at capacity'
  const subtitleText = userIsRsvped
    ? 'No new RSVPs accepted.'
    : 'You can check again later to see if any spots have opened up.'
  return (
    <div className="PlanInfoBanner Flex Flex--col Flex--align-center My-6">
      <i className="PlanInfoBanner__icon bi bi-info-circle Mb-3" />
      <h3>{headerText}</h3>
      <p className="Gray-text">{subtitleText}</p>
    </div>
  )
}
