import { gql } from '@apollo/client'

export const userConnectionsFindMutualFriends = gql`
  query userConnectionsFindMutualFriends($otherUserId: String!) {
    userConnections {
      findMutualFriends(otherUserId: $otherUserId, limit: 200) {
        items {
          id
          firstName
          imageUrl
        }
      }
    }
  }
`
