import { gql } from '@apollo/client'

export const interestsFind = gql`
  query interestsFind($userId: String!) {
    interests {
      find {
        id
        name
        didSelect(userId: $userId)
      }
    }
  }
`
