import { useSelector } from 'react-redux'
import { type Plan, RsvpType, type User } from '../../__generated__/graphql'
import EnhancedConfirmation from '../EnhancedConfirmation/EnhancedConfirmation'
import { EnhancedAuth } from '../EnhancedAuth/EnhancedAuth'

export const ConfirmationWithAuth = ({ plan, rsvpType }: { plan: Plan; rsvpType: RsvpType; onClose: () => void }) => {
  const user = useSelector((state: any) => state.user as User)

  if (!user?.id || !user.phoneNumber || !user.fullName || !user.imageUrl) {
    return <EnhancedAuth plan={plan} />
  }

  return <EnhancedConfirmation plan={plan} rsvpType={rsvpType} />
}
