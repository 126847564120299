import { useSelector } from 'react-redux'
import { User } from '../../__generated__/graphql'
import { FavoriteSpotPill } from './FavoriteSpotPill/FavoriteSpotPill'

export function FavoriteSpots({ user }: { user: User }): JSX.Element | null {
  const me: User = useSelector((state: any) => state.user as User)
  if (!user.favoriteSpots || !user.favoriteSpots?.length) return null

  return (
    <div className="FavoriteSpots">
      <h2 className="Mt-6 Mb-2">{me.id === user.id ? 'Your ' : 'Their '}Favorite Spots</h2>
      {user.favoriteSpots.map((spot) => (
        <FavoriteSpotPill key={spot.id} spot={spot} />
      ))}
    </div>
  )
}
