import './PublicPlans.scss'

import type { JSX } from 'react'
import { getPathForPlan } from '../../constants/path-names'
import { useNavigate } from 'react-router-dom'

export interface PublicPlanProps {
  id: string
  createdBy: string
  title: string
  startAt: string
  venue: string
  imageSrc: string
}

export function PublicPlan({ id, createdBy, title, startAt, venue, imageSrc }: PublicPlanProps): JSX.Element {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(getPathForPlan(id))
  }

  return (
    <div className="PublicPlans" onClick={handleClick}>
      <div className="PublicPlans__container">
        <div className="PublicPlans__info">
          <p className="PublicPlans__creator">{createdBy} created</p>
          <p className="PublicPlans__title">{title}</p>
          <p className="PublicPlans__details">
            {startAt}
            <br />
            {venue}
          </p>
        </div>
      </div>
      <aside className="PublicPlans__aside">
        <div className="PublicPlans__aside__box">
          <img src={`${imageSrc}?optimize=low&width=100`} alt={venue} />
        </div>
      </aside>
    </div>
  )
}
